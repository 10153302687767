import instance from "../Services/AxiosConfig";

class InfoMessageService {
    constructor() {
        this.messages = null;
        this.lastFetch = null;
    }

    async initializeMessages() {
        try {
            const response = await instance.get("/api/info-messages");
            this.messages = response.data;
            this.lastFetch = new Date();
            this.saveToLocalStorage();
            return this.messages;
        } catch (error) {
            console.error("Error fetching info messages:", error);
            return this.getFromLocalStorage();
        }
    }

    saveToLocalStorage() {
        localStorage.setItem(
            "infoMessages",
            JSON.stringify({
                messages: this.messages,
                timestamp: this.lastFetch,
            })
        );
    }

    getFromLocalStorage() {
        const stored = localStorage.getItem("infoMessages");
        if (!stored) return null;

        const { messages, timestamp } = JSON.parse(stored);
        const lastFetchDate = new Date(timestamp);
        const now = new Date();

        // Refresh if data is older than 24 hours
        if (now - lastFetchDate > 24 * 60 * 60 * 1000) {
            this.initializeMessages();
            return null;
        }

        return messages;
    }

    getMessagesForRoute(route) {
        const messages = this.messages || this.getFromLocalStorage();
        if (!messages) return [];

        return messages.filter((msg) => {
            // Check if message should be shown based on display frequency
            const dismissedMessages = JSON.parse(localStorage.getItem("dismissedMessages") || "{}");
            const messageKey = `${msg.id}-${msg.updatedAt}`;

            if (dismissedMessages[messageKey]) {
                const dismissedDate = new Date(dismissedMessages[messageKey]);
                const now = new Date();

                // Map API frequency values to time periods
                switch (msg.displayFrequency) {
                    case 0: // always show
                        return true;
                    case 1: // once
                        return false;
                    case 2: // daily
                        return (now - dismissedDate) >= (24 * 60 * 60 * 1000);
                    case 3: // weekly
                        return (now - dismissedDate) >= (7 * 24 * 60 * 60 * 1000);
                    default:
                        return true;
                }
            }

            return msg.route === route && msg.active;
        });
    }

    dismissMessage(messageKey) {
        const dismissedMessages = JSON.parse(localStorage.getItem("dismissedMessages") || "{}");
        dismissedMessages[messageKey] = new Date().toISOString();
        localStorage.setItem("dismissedMessages", JSON.stringify(dismissedMessages));
    }
}

export const infoMessageService = new InfoMessageService();

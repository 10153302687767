import React, { Suspense, useEffect, lazy, useState } from "react";
import { Route, Navigate, Routes, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Confetti from "react-confetti";
import { initAllContracts, loadAuctions } from "../Services/BlockChainService";
import useSound from "../Services/SoundService";
import { initializeAuctionContract } from "../Services/AlchemyAuctionInteract";
import AppFooter from "./Common/AppFooter";
import AppHeader from "./Common/AppHeader";
import ErrorBoundary from "./ErrorBoundary";
import { useLoading } from "./LoadingContext";
import HowItWorksScreen from "./HowItWorks";
import CookieConsentBanner from "./Cookies/CookieConsentBanner";
import { BlockchainOverlay } from "./Common/LoadingScreen";
import AchievementListenerWrapper from "./Achievements/AchievementListenerWrapper";
import { infoMessageService } from "../Services/InfoMessageService";
import UserProfileRewards from "./Profile/UserProfile";
import CookiePolicy from "../helpers/cookie-policy";
import PrivacyCompliance from "../helpers/privacy";
import truncateEthAddress from "truncate-eth-address";
import { useAppKitAccount } from "@reown/appkit/react";
import Cookies from "js-cookie";
// Lazy load all components
const HomePage = lazy(() => import("./HomePage"));
const AuctionsUI = lazy(() => import("../Feature/Auction/AuctionsUI"));
const AuctionDetails = lazy(() => import("../Feature/Auction/AuctionDetailsUI"));
// const CreateAuction = lazy(() => import("./CreateAuction"));
// const GovernanceVoting = lazy(() => import("./GovernanceUI"));
// const UserProfileRewards = lazy(() => import("./Profile/UserProfile"));
// const NotificationCenter = lazy(() => import("./NotificationCenter"));
const StakingInterface = lazy(() => import("./StakingUI"));
const PresaleUI = lazy(() => import("./PresaleUI"));
const ReferralCommission = lazy(() => import("./ReferralUI"));
const TermsAndConditionsPage = lazy(() => import("./TermsAndCondtions"));
const LegalRefundPage = lazy(() => import("./LegalRefund"));
const RefundPage = lazy(() => import("./Refund"));
const AirdropSubscription = lazy(() => import("./AirDrop/AirDropUI"));
const Error404 = lazy(() => import("./Error404"));
/**
 *
 */
const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    useEffect(() => {
        /**
         *
         */
        const handleResize = () => {
            setWindowSize({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
};

/**
 *
 */
const CustomAppRouter = () => {
    const { account, isConnected } = useAppKitAccount();
    const { isLoading, setIsLoading } = useLoading();
    const [consent, setConsent] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const { width, height } = useWindowSize(); // Add this hook to get window dimensions
    const playBidSound = useSound("/sounds/place_bid_sound.mp3");
    // useSound("/sounds/place_bid_sound.mp3");
    const playAuctionEnd = useSound("/sounds/place_bid_sound.mp3");
    const [messages, setMessages] = useState([]);
    const location = useLocation(); // Add this hook to get current route
    const navigate = useNavigate();

    // Add new state for referral tracking
    const [referralCode, setReferralCode] = useState(null);

    /**
     *
     */
    const handleAcceptCookies = () => {
        setConsent(true);
    };

    useEffect(() => {
        /**
         *
         */
        const initContracts = async () => {
            setIsLoading(true);
            await initAllContracts();
            subscribeToAllBidEvents();
            setIsLoading(false);
        };
        initContracts();
    }, [setIsLoading]);
    useEffect(() => {
        const initMessages = async () => {
            await infoMessageService.getFromLocalStorage();
            const routeMessages = infoMessageService.getMessagesForRoute(location.pathname);
            setMessages(routeMessages);
        };

        initMessages();
    }, [location.pathname]);

    // Update referral code handler
    useEffect(() => {
        const handleReferralCode = () => {
            const pathParts = location.pathname.split("/");
            if (pathParts[1] === "ref" && pathParts[2]) {
                const code = pathParts[2];
                // Store referral code in localStorage for persistence
                Cookies.set("referralCode", code);
                localStorage.setItem("referralCode", code);
                setReferralCode(code);

                // Instead of navigating directly, trigger onboarding with referral
                localStorage.removeItem("onboardingComplete");
                navigate("/", { state: { referralCode: code } });
            }
        };

        handleReferralCode();
    }, [location, navigate]);

    const handleDismiss = (message) => {
        const messageKey = `${message.id}-${message.updatedAt}`;
        infoMessageService.dismissMessage(messageKey);
        setMessages(messages.filter((msg) => msg.id !== message.id));
    };
    /**
     *
     */
    const subscribeToAllBidEvents = async () => {
        const auctionArray = await loadAuctions();
        if (auctionArray) {
            for (const auctionAddress of auctionArray) {
                const auctionContract = initializeAuctionContract(auctionAddress);
                auctionContract.events.BidPlaced({}, async (error, data) => {
                    if (error) {
                        setStatus("😥 " + error.message);
                    } else {
                        const res = data.returnValues;
                        // Check if the event was emitted by the userAddress
                        // console.log(
                        //   "🚀 ~ auctionContract.events.BidPlaced ~ es[0].toLowerCase():",
                        //   res[0].toLowerCase()
                        // );

                        console.log(
                            "BidPlaced Event emitted by: " +
                                res[0] +
                                ", currentBidPrice: " +
                                res[1] +
                                ", numberOfBids: " +
                                res[2] +
                                ", isBurned: " +
                                res[3]
                        );
                        playBidSound();

                        toast.success(truncateEthAddress(res[0]) + " ~ placed a bid");
                        // setShowConfetti(true);
                        // setTimeout(() => {
                        //   setShowConfetti(false);
                        // }, 3000);
                    }
                });
                auctionContract.events.BidBurned({}, async (error, data) => {
                    if (error) {
                        setStatus("😥 " + error.message);
                    } else {
                        const res = data.returnValues;
                        // Check if the event was emitted by the userAddress
                        // console.log(
                        //   "🚀 ~ auctionContract.events.BidPlaced ~ es[0].toLowerCase():",
                        //   res[0].toLowerCase()
                        // );

                        console.log(
                            "BidBurned Event emitted by: " +
                                res[0] +
                                ", currentBidPrice: " +
                                res[1] +
                                ", numberOfBids: " +
                                res[2] +
                                ", isBurned: " +
                                res[3]
                        );
                        playBidSound();

                        toast.success(truncateEthAddress(res[0]) + " ~ burned a bid");
                        // setShowConfetti(true);
                        // setTimeout(() => {
                        //   setShowConfetti(false);
                        // }, 3000);
                    }
                });
                auctionContract.events.AuctionEnded({}, async (error, data) => {
                    if (error) {
                        setStatus("😥 " + error.message);
                    } else {
                        const res = data.returnValues;
                        // Check if the event was emitted by the userAddress
                        // console.log(
                        //   "🚀 ~ auctionContract.events.BidPlaced ~ es[0].toLowerCase():",
                        //   res[0].toLowerCase()
                        // );
                        playAuctionEnd();
                        console.log(
                            "AuctionEnded Event emitted by: " +
                                res[0] +
                                ", currentBidPrice: " +
                                res[1] +
                                ", numberOfBids: " +
                                res[2] +
                                ", isBurned: " +
                                res[3]
                        );

                        // toast.success(res[0] + " ~ placed a bid");
                        // setShowConfetti(true);
                        // setTimeout(() => {
                        //   setShowConfetti(false);
                        // }, 3000);
                    }
                });
            }
        }
    };

    // if (isLoading) {
    //   return <LoadingScreen />;
    // }
    return (
        // min-h-screen bg-center subpixel-antialiased bg-gray-900 text-white mt-4
        <div className="">
            <AchievementListenerWrapper>
                {showConfetti && <Confetti width={width} height={height} gravity={0.618} />}
                <AppHeader />
                {/* Add Messages Display */}
                <div className="messages-container">
                    {messages.map((msg) => (
                        <div key={msg.id} className={`message-alert type-${msg.type}`}>
                            {msg.message}
                            {msg.hasImage && (
                                <img src={msg.imageUrl} alt="" className="message-image" />
                            )}
                            {msg.dismissible && (
                                <button onClick={() => handleDismiss(msg)} className="dismiss-btn">
                                    ×
                                </button>
                            )}
                        </div>
                    ))}
                </div>
                <ErrorBoundary>
                    <Suspense fallback={<BlockchainOverlay loading={isLoading} />}>
                        <Routes>
                            {/* Add explicit referral route */}
                            <Route
                                path="/ref/:referralCode"
                                element={
                                    <Navigate
                                        to="/"
                                        replace
                                        state={{ referralCode: location.pathname.split("/")[2] }}
                                    />
                                }
                            />

                            {/* Modify HomePage route to handle referral state */}
                            <Route
                                path="/"
                                element={
                                    <HomePage
                                        referralCode={referralCode}
                                        onRegisterWithReferral={(code) => {
                                            // Handle registration with referral
                                            console.log("Registering with referral:", code);
                                            // Add your registration logic here
                                        }}
                                    />
                                }
                            />

                            <Route path="/Airdrop" element={<AirdropSubscription />} />
                            <Route path="/HomePage" element={<HomePage />} />
                            <Route path="/Auctions" element={<AuctionsUI />} />
                            {/* <Route path="/AuctionDetails" element={<AuctionDetails />} /> */}
                            <Route path="/AuctionDetails/:address" element={<AuctionDetails />} />
                            {/* <Route path="/CreateAuction" element={<CreateAuction />} /> */}
                            {/* <Route path="/GovernanceVoting" element={<GovernanceVoting />} /> */}
                            <Route path="/HowItWorks" element={<HowItWorksScreen />} />
                            {/* <Route path="/profile" element={<UserProfileRewards />} /> */}
                            <Route path="/profile/settings" element={<UserProfileRewards />} />
                            <Route path="/profile/achievements" element={<UserProfileRewards />} />
                            <Route path="/profile" element={<UserProfileRewards />} />
                            {/* <Route path="/notifications" element={<NotificationCenter />} /> */}
                            <Route path="/StakingInterface" element={<StakingInterface />} />
                            <Route
                                path="/term-and-conditions"
                                element={<TermsAndConditionsPage />}
                            />
                            <Route path="/legalrefund" element={<LegalRefundPage />} />
                            <Route path="/refund" element={<RefundPage />} />
                            <Route path="/cookie-policy" element={<CookiePolicy />} />
                            <Route path="/privacy" element={<PrivacyCompliance />} />
                            <Route path="/presale" element={<PresaleUI />} />
                            {/* <Route path="/referral" element={<ReferralCommission />} /> */}
                            <Route path="*" element={<Error404 />} />
                        </Routes>
                        <CookieConsentBanner onAccept={handleAcceptCookies} />
                    </Suspense>
                </ErrorBoundary>
                <AppFooter />
                <ToastContainer
                    position="top-left"
                    autoClose={1618}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </AchievementListenerWrapper>
        </div>
    );
};
export default CustomAppRouter;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import Web3 from "web3";
import {
    initializeAuctionContract,
    initializeAuctionFactoryContract,
    initializeTreasuryContract,
    initializeGovernanceContract,
} from "../../Services/ContractInitializers";
import { loadAuctions } from "../../Services/BlockChainService";
import { stakingContract } from "../../Services/AlchemyStakingInteract";
import { BlockchainOverlay } from "../Common/LoadingScreen";
import { useAppKitAccount } from "@reown/appkit/react";
/**
 *
 */

/**
 *
 */
const ProgressBarSkeleton = () => (
    <div className="mt-2 bg-zinc-700 rounded-full h-2.5 dark:bg-zinc-700 animate-pulse">
        <div className="bg-zinc-600 h-2.5 rounded-full w-full"></div>
    </div>
);

const AchievementItem = React.memo(({ achievement, loading }) => {
    return (
        <div
            className={`bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6 ${
                !achievement.isUnlocked ? "opacity-50" : ""
            } hover:border-zinc-600/50 transition-all duration-500 ease-out`}
        >
            <img
                src={`${process.env.REACT_APP_API_BASE_URL}${achievement.icon}`}
                alt={achievement.name}
                className={`w-16 h-16 mx-auto mb-2 ${
                    !achievement.isUnlocked ? "filter grayscale" : ""
                } transition-all duration-500 ease-out`}
            />
            <h3 className="text-lg font-medium text-zinc-100 text-center">{achievement.name}</h3>
            <p className="text-sm text-zinc-400 text-center">{achievement.description}</p>
            {loading ? (
                <ProgressBarSkeleton />
            ) : (
                <>
                    <div className="mt-2 bg-zinc-700 rounded-full h-2.5">
                        <div
                            className="bg-amber-500 h-2.5 rounded-full transition-all duration-500 ease-out"
                            style={{
                                width: `${
                                    (achievement.progress / achievement.requirements[0].value) * 100
                                }%`,
                            }}
                        ></div>
                    </div>
                    <p className="text-xs text-zinc-400 text-center mt-1">
                        {achievement.progress} / {achievement.requirements[0].value}
                    </p>
                </>
            )}
        </div>
    );
});

/**
 *
 */
const AchievementsDisplay = ({ achievements }) => {
    const address = localStorage.getItem("userAddress");
    const [unlockedAchievements, setUnlockedAchievements] = useState(achievements || []);
    const [loading, setLoading] = useState(true);
    const [eventCounts, setEventCounts] = useState({});

    useEffect(() => {
        if (achievements && achievements.length > 0) {
            setUnlockedAchievements(achievements);
        }
    }, [achievements]);

    const eventGroups = useMemo(
        () => ({
            AuctionFactory: [
                {
                    name: "AuctionCreated",
                    signature: "AuctionCreated(address,address,uint256)",
                },
                { name: "AuctionRemoved", signature: "AuctionRemoved(address)" },
            ],
            GovernanceContract: [
                { name: "Voted", signature: "Voted(uint256,address,bool,uint256)" },
            ],
            NewAuction: [
                {
                    name: "AuctionInitialized",
                    signature: "AuctionInitialized(address)",
                },
                {
                    name: "BidPlaced",
                    signature: "BidPlaced(address,uint256,uint256)",
                },
                {
                    name: "BidBurned",
                    signature: "BidBurned(address,uint256,uint256)",
                },
                { name: "AuctionEnded", signature: "AuctionEnded(address,uint256)" },
                { name: "RefundClaimed", signature: "RefundClaimed(address,uint256)" },
                { name: "AssetDelivered", signature: "AssetDelivered(address)" },
                {
                    name: "OwnerConfirmedDelivery",
                    signature: "OwnerConfirmedDelivery(address)",
                },
                {
                    name: "WinnerConfirmedDelivery",
                    signature: "WinnerConfirmedDelivery(address)",
                },
            ],
            StakingContract: [
                { name: "Staked", signature: "Staked(address,uint256)" },
                { name: "Unstaked", signature: "Unstaked(address,uint256)" },
                {
                    name: "UnstakeRequested",
                    signature: "UnstakeRequested(address,uint256)",
                },
                {
                    name: "RewardDistributed",
                    signature: "RewardDistributed(address,uint256)",
                },
                { name: "RewardClaimed", signature: "RewardClaimed(address,uint256)" },
            ],
            Treasury: [{ name: "RewardClaimed", signature: "RewardClaimed(address,uint256)" }],
        }),
        []
    );

    const queryEventsForContract = useCallback(
        async (contract, eventGroup) => {
            if (!address || eventGroup.length === 0 || contract === undefined) {
                return {};
            }
            const counts = {};
            for (const event of eventGroup) {
                const eventSignature = Web3.utils.sha3(event.signature);
                try {
                    const events = await contract.getPastEvents(event.name, {
                        fromBlock: 0,
                        toBlock: "latest",
                        topics: [eventSignature],
                    });
                    const filterCount = events.filter(
                        (e) => e.returnValues[0].toLowerCase() === address.toLowerCase()
                    );
                    const userEvents = filterCount;
                    counts[event.name] = userEvents.length;
                } catch (error) {
                    // console.warn(
                    //   `Event ${event.name} not found or not emitted for contract ${contract.address}:`,
                    //   error.message
                    // );
                }
            }

            return counts;
        },
        [address]
    );

    const fetchEventCounts = useCallback(async () => {
        if (!address) {
            return;
        }

        const auctionArray = await loadAuctions();
        const contracts = {
            AuctionFactory: initializeAuctionFactoryContract(
                process.env.REACT_APP_AUCTIONFACTORY_ADDRESS
            ),
            GovernanceContract: initializeGovernanceContract(
                process.env.REACT_APP_GOVERNANCECONTRACT_ADDRESS
            ),
            StakingContract: stakingContract,
            Treasury: initializeTreasuryContract(process.env.REACT_APP_TREASURY_ADDRESS),
        };

        const counts = {};
        await Promise.all(
            Object.entries(eventGroups).map(async ([contractName, events]) => {
                if (
                    contractName === "NewAuction" ||
                    contractName === "DynamicAuctionSoloV3" ||
                    contractName === "AuctionImplV4"
                ) {
                    const auctionCounts = {};
                    await Promise.all(
                        auctionArray.map(async (auctionAddress) => {
                            const auctionContract = initializeAuctionContract(auctionAddress);
                            const auctionEventCounts = await queryEventsForContract(
                                auctionContract,
                                events
                            );
                            Object.keys(auctionEventCounts).forEach((key) => {
                                auctionCounts[key] =
                                    (auctionCounts[key] || 0) + auctionEventCounts[key];
                            });
                        })
                    );
                    Object.assign(counts, auctionCounts);
                    console.log("🚀 ~ fetchEventCounts ~ auctionCounts:", auctionCounts);
                } else {
                    const contractCounts = await queryEventsForContract(
                        contracts[contractName],
                        events
                    );
                    Object.assign(counts, contractCounts);
                }
            })
        );

        setEventCounts(counts);
    }, [eventGroups, queryEventsForContract]);

    const handleRefresh = useCallback(async () => {
        if (!address) return;
        setLoading(true);
        await fetchEventCounts();
        setLoading(false);
    }, [fetchEventCounts, address]);

    useEffect(() => {
        if (!address) {
            console.log("User address is not set.");
            return;
        }

        const checkAchievements = async () => {
            setLoading(true);
            console.log("Fetching event counts...");
            await fetchEventCounts();
            setLoading(false);
            console.log("Event counts fetched, loading set to false.");
        };

        checkAchievements();
    }, [fetchEventCounts, address]);

    useEffect(() => {
        if (!loading && address && achievements.length > 0) {
            const updatedAchievements = achievements.map((achievement) => {
                let isUnlocked = false;
                let progress = 0;

                achievement.requirements.forEach((requirement) => {
                    const eventCount = eventCounts[requirement.description] || 0;
                    progress = Math.min(eventCount, requirement.value);
                    isUnlocked = eventCount >= requirement.value;
                });

                return { ...achievement, isUnlocked, loading: false, progress };
            });

            console.log("Updated Achievements:", updatedAchievements);
            setUnlockedAchievements(updatedAchievements);
            localStorage.setItem("userAchievements", JSON.stringify(updatedAchievements));
        }
    }, [achievements, eventCounts, loading, address]);

    return (
        <>
            <BlockchainOverlay loading={loading} />
            <div className="flex justify-end mb-4">
                <button
                    onClick={handleRefresh}
                    disabled={loading}
                    className="flex items-center gap-2 px-4 py-2 bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-lg hover:border-zinc-600/50  text-zinc-100 transition-all duration-500 ease-out disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    <svg
                        className={`w-4 h-4 ${loading ? "animate-spin" : ""}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        />
                    </svg>
                    Refresh
                </button>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                {unlockedAchievements && unlockedAchievements.length > 0 ? (
                    unlockedAchievements
                        .sort((a, b) => (a.isUnlocked === b.isUnlocked ? 0 : a.isUnlocked ? -1 : 1))
                        .map((achievement) => (
                            <AchievementItem
                                key={achievement.id}
                                achievement={achievement}
                                loading={achievement.loading}
                            />
                        ))
                ) : (
                    <div className="col-span-full text-center text-zinc-400 py-8">
                        No achievements available
                    </div>
                )}
            </div>
        </>
    );
};

export default AchievementsDisplay;

import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from "react";
import instance from "../Services/AxiosConfig";
// import LoadingScreen from "../Components/LoadingScreen";
import { updateUserWalletData } from "../Services/BlockChainService";
import { toast } from "react-toastify";
import { getCookieConsentValue } from "react-cookie-consent";
import LogRocket from "logrocket";
import { modal } from "../Components/AppRouter";
import Cookies from "js-cookie";

const AuthContext = createContext({
    error: null,
    lastRefCode: null,
    loading: true,
    login: async () => {},
    logout: async () => {},
    register: async () => {},
    setRefCode: async () => {},
    setUser: async () => {},
    user: null,
    referralStats: null,
    connectWallet: async () => {},
    getReferralStats: async () => {},
});

/**
 *
 */
export const AuthProvider = ({ children }) => {
    const [state, setState] = useState({
        error: null,
        lastRefCode: null,
        loading: true,
        user: null,
        referralStats: null,
    });
    const toastShownRef = useRef(false);
    const processReferral = useCallback(async () => {
        const referralCode = Cookies.get("referralCode");
        // const lastRefCode = localStorage.getItem("lastRefCode");
        const siweUser = localStorage.getItem("siwe-user");
        console.log("🚀 ~ initAuth ~ siweUser:", siweUser);
        if (siweUser) {
            const parsedUser = JSON.parse(siweUser);
            if (referralCode) {
                setRefCode(referralCode);
                const response = await instance.post("/api/Auth/process-referral", {
                    referralCode: referralCode,
                    referredUserId: parsedUser.user.id,
                });
                console.log("🚀 ~ processReferral ~ response:", response);
                if (response.status === 202) {
                    console.log("🚀 ~ processReferral ~ response:", response);
                    Cookies.remove("referralCode");
                    // localStorage.removeItem("lastRefCode");
                    return;
                }
                if (response.data.success) {
                    toast.success("Referral code applied successfully!");
                } else {
                    // toast.error("Failed to apply referral code.");
                }
            }
        }
    }, []);
    // Add referral stats fetching
    const getReferralStats = useCallback(async () => {
        try {
            const response = await instance.get("/api/Auth/referral-stats");
            const stats = response.data;
            setState((prev) => ({ ...prev, referralStats: stats }));
            return stats;
        } catch (error) {
            console.error("Failed to fetch referral stats:", error);
            return null;
        }
    }, []);
    // Add wallet connection
    const connectWallet = useCallback(async (walletAddress) => {
        try {
            const response = await instance.post("/api/Auth/connect-wallet", {
                walletAddress,
            });
            setState((prev) => ({
                ...prev,
                user: {
                    ...prev.user,
                    connectedWallets: [...(prev.user.connectedWallets || []), walletAddress],
                },
            }));
            toast.success("Wallet connected successfully!");
            return true;
        } catch (error) {
            console.error("Failed to connect wallet:", error);
            toast.error(error.response?.data?.message || "Failed to connect wallet");
            return false;
        }
    }, []);
    const setUser = useCallback(
        (userData) => {
            const { token, refreshToken, user } = userData;
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("auth-user", JSON.stringify(userData));
            const walletAddress = localStorage.getItem("userAddress");
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

            if (walletAddress) {
                // connectWallet(walletAddress);
            }
            // if (localStorage.getItem("referralCode")) {
            //     processReferral();
            // }
            LogRocket.identify(user.id, {
                email: user.email,
                name: user.name,
                siweLogin: true,
                walletAddress: user.walletAddress,
                referredBy: user.referredBy,
            });

            setState((prevState) => ({
                ...prevState,
                error: null,
                loading: false,
                user,
            }));

            // Fetch referral stats when user is set
            // getReferralStats();
        },
        []
        // [getReferralStats]
    );
    // Modified SIWE verification handler
    const handleSiweAuthComplete = useCallback(
        (event) => {
            const { token, refreshToken, user } = event.detail;
            setUser({ token, refreshToken, user });

            // Check for referral code in cookies
            const referralCode = Cookies.get("referralCode");
            if (referralCode) {
                // Clear the referral cookie after use
                Cookies.remove("referralCode");
            }
        },
        [setUser]
    );
    useEffect(() => {
        window.addEventListener("SIWE_AUTH_COMPLETE", handleSiweAuthComplete);
        window.addEventListener("SIWX_AUTH_SUCCESS", handleSiweAuthComplete);
        return () => {
            window.removeEventListener("SIWE_AUTH_COMPLETE", handleSiweAuthComplete);
        };
    }, [handleSiweAuthComplete]);
    const setRefCode = useCallback(async (code) => {
        try {
            localStorage.setItem("lastRefCode", code);
            setState((prevState) => ({ ...prevState, lastRefCode: code }));
        } catch (error) {
            console.error("Error saving ref code:", error);
            setState((prevState) => ({
                ...prevState,
                error: "Failed to save referral code",
            }));
        }
    }, []);

    const refreshToken = useCallback(async () => {
        try {
            const response = await instance.post("/api/Auth/refresh-token", {
                refreshToken: localStorage.getItem("refreshToken"),
                token: localStorage.getItem("token"),
            });
            const { token, refreshToken, user } = response.data.data;
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            setState((prevState) => ({ ...prevState, user }));
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            LogRocket.identify(user.id, {
                email: user.email,
                name: user.name,

                // Add your own custom user variables here, ie:
                // subscriptionType: 'pro'
            });

            return token;
        } catch (error) {
            console.error("Failed to refresh token:", error);
            await logout();

            return null;
        }
    }, []);

    const updateWalletData = useCallback(async (walletData) => {
        try {
            await updateUserWalletData(walletData);
        } catch (error) {
            console.error("Error updating wallet data:", error);
            setState((prevState) => ({
                ...prevState,
                error: "Failed to update wallet data",
            }));
        }
    }, []);

    const initAuth = useCallback(async () => {
        const consent = getCookieConsentValue("yourCookieConsent");
        if (!consent) {
            await silentLogout();
            setState((prevState) => ({ ...prevState, loading: false }));
            return;
        }

        const siweUser = localStorage.getItem("siwe-user");
        console.log("🚀 ~ initAuth ~ siweUser:", siweUser);
        if (siweUser) {
            try {
                const parsedUser = JSON.parse(siweUser);
                setUser(parsedUser);
                return { data: parsedUser, success: true };
            } catch (error) {
                console.error("Failed to parse SIWE user:", error);
            }
        }

        // if (siweUser) {
        //     try {
        //         const parsedUser = JSON.parse(siweUser);
        //         localStorage.setItem("token", parsedUser.token);
        //         console.log("🚀 ~ initAuth ~ parsedUser.token:", parsedUser.token);
        //         localStorage.setItem("refreshToken", parsedUser.refreshToken);
        //         console.log("🚀 ~ initAuth ~ parsedUser.refreshToken:", parsedUser.refreshToken);
        //         setState((prevState) => ({
        //             ...prevState,
        //             error: null,
        //             loading: false,
        //             user: parsedUser.user,
        //         }));
        //         instance.defaults.headers.common["Authorization"] = `Bearer ${parsedUser.token}`;
        //         LogRocket.identify(parsedUser.id, {
        //             email: parsedUser.email,
        //             name: parsedUser.name,
        //             siweLogin: true,
        //             // Add your own custom user variables here, ie:
        //             // subscriptionType: 'pro'
        //         });
        //         setState((prevState) => ({
        //             ...prevState,
        //             error: null,
        //             loading: false,
        //             user: parsedUser.user,
        //         }));

        //         return { data: parsedUser, success: true };
        //     } catch (error) {
        //         console.error("Failed to parse SIWE user:", error);
        //     }
        // }

        const token = localStorage.getItem("token");
        if (token) {
            try {
                instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                const response = await instance.get("/api/Auth/me");
                const user = response.data.data.user;
                setState((prevState) => ({ ...prevState, loading: false, user }));
                if (user.walletData) {
                    await updateWalletData(user.walletData);
                }

                if (!toastShownRef.current) {
                    toast("Welcome Back!");
                    LogRocket.identify(user.id, {
                        cookieLogin: consent,
                        email: user.email,
                        name: user.name,

                        // Add your own custom user variables here, ie:
                        // subscriptionType: 'pro'
                    });
                    toastShownRef.current = true;
                }
            } catch (error) {
                console.error("Failed to fetch user data:", error);
                if (error.response && error.response.status === 401) {
                    const newToken = await refreshToken();
                    if (newToken) {
                        try {
                            const retryResponse = await instance.get("/api/Auth/me");
                            const user = retryResponse.data.data.user;
                            setState((prevState) => ({ ...prevState, loading: false, user }));
                            if (user.walletData) {
                                await updateWalletData(user.walletData);
                            }
                        } catch (retryError) {
                            console.error(
                                "Failed to fetch user data after token refresh:",
                                retryError
                            );
                            await logout();
                        }
                    } else {
                        await logout();
                    }
                } else {
                    await logout();
                }
            }
        } else {
            await logout();
        }
    }, [refreshToken, updateWalletData, setUser]);

    useEffect(() => {
        initAuth();
    }, [initAuth]);

    const register = useCallback(async (formData) => {
        try {
            const response = await instance.post("/api/Auth/register", formData);
            const { token, refreshToken, user } = response.data.data;
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            setState((prevState) => ({ ...prevState, error: null, user }));
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            LogRocket.identify(user.id, {
                email: user.email,
                name: user.name,
                register: true,

                // Add your own custom user variables here, ie:
                // subscriptionType: 'pro'
            });

            return { data: user, success: true };
        } catch (error) {
            console.error("Error Registering user:", error.response?.data || error.message);
            setState((prevState) => ({ ...prevState, error: "Registration failed" }));

            return { error: error.response?.data || error.message, success: false };
        }
    }, []);

    const login = useCallback(async (username, password) => {
        try {
            const response = await instance.post("/api/Auth/login", {
                password,
                username,
            });
            const { token, refreshToken, user } = response.data.data;
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("auth-user", JSON.stringify(user));
            setState((prevState) => ({ ...prevState, error: null, user }));
            instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            LogRocket.identify(user.id, {
                email: user.email,
                manualLogin: true,
                name: user.name,
                // Add your own custom user variables here, ie:
                // subscriptionType: 'pro'
            });

            return { success: true };
        } catch (error) {
            console.error("Login failed:", error);
            let errorMessage = "Login failed. Please try again.";
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        errorMessage = "Invalid credentials. Please check your input.";
                        break;
                    case 401:
                        errorMessage = "Incorrect username or password.";
                        break;
                    case 404:
                        errorMessage = "User not found.";
                        break;
                    case 429:
                        errorMessage = "Too many login attempts. Please try again later.";
                        break;
                    default:
                        errorMessage = "An error occurred. Please try again.";
                }
            }
            setState((prevState) => ({ ...prevState, error: errorMessage }));

            return { error: errorMessage, success: false };
        }
    }, []);

    const logout = useCallback(async () => {
        try {
            const token = localStorage.getItem("token");
            await instance.post("/api/Auth/logout", null, {
                headers: { Authorization: `Bearer ${token}` },
            });
        } catch (error) {
            console.error("Logout failed:", error);
        } finally {
            modal.adapter?.connectionControllerClient?.disconnect();
            localStorage.removeItem("token");
            localStorage.removeItem("dismissedMessages");
            localStorage.removeItem("infoMessages");
            localStorage.removeItem("pw");
            localStorage.removeItem("lastRefCode");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("siwe-user");
            localStorage.removeItem("siwe-user-nonce");
            localStorage.clear();
            document.cookie = "authCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie =
                ".AspNetCore.Cookies=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            delete instance.defaults.headers.common["Authorization"];
            localStorage.removeItem("signed");
            localStorage.removeItem("onboardingComplete");
            setState((prevState) => ({ ...prevState, error: null, user: null }));
            if (!toastShownRef.current) {
                // toast("See you soon!");
                // toastShownRef.current = true;
            }
        }
    }, []);

    const silentLogout = useCallback(async () => {
        try {
            const token = localStorage.getItem("token");
            await instance.post("/api/Auth/logout", null, {
                headers: { Authorization: `Bearer ${token}` },
            });
        } catch (error) {
            console.error("Silent logout failed:", error);
        } finally {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            document.cookie = "authCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie =
                ".AspNetCore.Cookies=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            delete instance.defaults.headers.common["Authorization"];
            localStorage.removeItem("signed");
            setState((prevState) => ({ ...prevState, error: null, user: null }));
        }
    }, []);

    const updateUser = useCallback(async (userData) => {
        try {
            setState((prev) => ({
                ...prev,
                user: { ...prev.user, ...userData },
            }));
            return { success: true };
        } catch (error) {
            console.error("Error updating user:", error);
            return { success: false, error: error.message };
        }
    }, []);

    // if (state.loading) {
    //   return <LoadingScreen />;
    // }

    const contextValue = {
        ...state,
        login,
        logout,
        register,
        setRefCode,
        setUser,
        updateUser,
        connectWallet,
        getReferralStats,
    };

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

/**
 *
 */
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
};

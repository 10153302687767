import { createSIWEConfig, formatMessage } from "@reown/appkit-siwe";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// call the server to get a nonce
/**
 *
 */
const getNonce = async () => {
    const res = await fetch(BASE_URL + "/api/Siwe/nonce", {
        credentials: "include",
        method: "GET",
    });
    if (!res.ok) {
        throw new Error("Network response was not ok");
    }
    const nonce = await res.text();
    console.log("Nonce:", nonce);

    return nonce;
};

//TODO call the server to verify the message ++ TODO: add address+chainId to the body
/**
 *
 */

const verifyMessage = async ({ message, signature }) => {
    try {
        const referralCode = Cookies.get("referralCode");
        const response = await fetch(BASE_URL + "/api/Siwe/verify", {
            body: JSON.stringify({
                message,
                signature,
                // referralCode: referralCode || null,
            }),
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            mode: "cors",
        });

        if (!response.ok) {
            console.error("Verify message failed:", await response.text());
            return false;
        }

        const result = await response.json();

        // Store user data
        localStorage.setItem("siwe-user", JSON.stringify(result));

        // Dispatch auth complete event
        window.dispatchEvent(
            new CustomEvent("SIWE_AUTH_COMPLETE", {
                detail: result,
            })
        );

        // Clear referral code after successful verification
        if (referralCode) {
            Cookies.remove("referralCode");
        }

        return true;
    } catch (error) {
        console.error("Verify message error:", error);
        return false;
    }
};

// call the server to get the session
/**
 *
 */
const getSession = async () => {
    const res = await fetch(BASE_URL + "/api/Siwe/session", {
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        method: "GET",
    });
    if (!res.ok) {
        throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data == "{}" ? null : null;
};

// call the server to sign out
/**
 *
 */
const signOut = async () => {
    const res = await fetch(BASE_URL + "/api/Siwe/signout", {
        credentials: "include",
        method: "POST",
    });
    if (!res.ok) {
        throw new Error("Network response was not ok");
    }

    const data = await res.json();

    return data == "{}";
};

/**
 *
 */

export const createSIWE = (chains) => {
    return createSIWEConfig({
        createMessage: ({ address, ...args }) => {
            return formatMessage(args, address);
        },
        getMessageParams: async () => ({
            aud: window.location.origin,
            chains: chains.map((chain) => parseInt(chain.id.toString())),
            domain: window.location.host,
            expiry: 3600,
            statement: `I accept the MetaMask Terms of Service: https://community.metamask.io/tos\n\nURI: https://${
                window.location.host
            }\nVersion: 1\nChain ID: ${
                chains[0].id
            }\nNonce: ${await getNonce()}\nIssued At: ${new Date().toISOString()}`,
            uri: window.location.origin,
        }),
        getNonce,
        getSession,
        signOut,
        signOutOnAccountChange: true,
        signOutOnNetworkChange: true,
        verifyMessage,
    });
};

import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { Link } from "react-router-dom";

const CookiePolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Cookie Policy - BidCoin</title>
                <meta name="description" content="BidCoin's Cookie Policy" />
            </Helmet>

            <div className="min-h-screen bg-gradient-to-br from-zinc-900 via-zinc-900 to-zinc-800/50 pt-16">
                <main className="max-w-4xl mx-auto px-4 py-16">
                    <h1 className="text-3xl font-bold text-zinc-100 mb-8">Cookie Policy</h1>
                    <p className="text-zinc-400 mb-8">
                        Effective Date: {new Date().toLocaleDateString()}
                    </p>

                    <div className="prose prose-invert">
                        <p className="text-zinc-300">
                            This Cookie Policy explains how BidCoin ("we," "us," or "our") uses
                            cookies and similar technologies on our website{" "}
                            <Link
                                to="/"
                                className="text-amber-500 hover:text-amber-400 transition-colors"
                            >
                                bidcoin.live
                            </Link>
                            . By continuing to use the Website, you agree to the use of cookies as
                            outlined in this policy.
                        </p>

                        {/* What Are Cookies? */}
                        <section className="mb-8">
                            <h2 className="text-xl font-semibold text-amber-500 mb-4">
                                1. What Are Cookies?
                            </h2>
                            <p className="text-zinc-300">
                                Cookies are small text files stored on your device (computer,
                                tablet, smartphone) when you visit a website. They help the website
                                function efficiently, remember your preferences, and provide
                                insights to improve user experience.
                            </p>
                        </section>

                        {/* Types of Cookies */}
                        <section className="mb-8">
                            <h2 className="text-xl font-semibold text-amber-500 mb-4">
                                2. Types of Cookies We Use
                            </h2>
                            <div className="space-y-4">
                                {/* Essential Cookies */}
                                <div>
                                    <h3 className="text-lg font-medium text-zinc-100">
                                        a. Essential Cookies
                                    </h3>
                                    <p className="text-zinc-300">
                                        These cookies are necessary for the Website to function
                                        properly. Without them, certain features and services may
                                        not be available.
                                    </p>
                                    <p className="text-zinc-300 mt-2">Examples:</p>
                                    <ul className="list-disc pl-6 text-zinc-300">
                                        <li>
                                            Session management cookies to enable secure login and
                                            navigation.
                                        </li>
                                    </ul>
                                </div>

                                {/* Functional Cookies */}
                                <div>
                                    <h3 className="text-lg font-medium text-zinc-100">
                                        b. Functional Cookies
                                    </h3>
                                    <p className="text-zinc-300">
                                        These cookies help us remember your preferences and provide
                                        a more personalized experience.
                                    </p>
                                    <p className="text-zinc-300 mt-2">Examples:</p>
                                    <ul className="list-disc pl-6 text-zinc-300">
                                        <li>Language preferences</li>
                                        <li>Settings for UI and accessibility features</li>
                                    </ul>
                                </div>

                                {/* Analytics Cookies */}
                                <div>
                                    <h3 className="text-lg font-medium text-zinc-100">
                                        c. Analytics Cookies
                                    </h3>
                                    <p className="text-zinc-300">
                                        These cookies collect anonymous information about how users
                                        interact with the Website. This helps us understand usage
                                        patterns and improve the Website.
                                    </p>
                                    <p className="text-zinc-300 mt-2">Examples:</p>
                                    <ul className="list-disc pl-6 text-zinc-300">
                                        <li>
                                            Google Analytics or other third-party tools to track
                                            page visits and user interactions
                                        </li>
                                    </ul>
                                </div>

                                {/* Performance Cookies */}
                                <div>
                                    <h3 className="text-lg font-medium text-zinc-100">
                                        d. Performance Cookies
                                    </h3>
                                    <p className="text-zinc-300">
                                        These cookies help us optimize the Website by tracking
                                        loading speeds, error rates, and user navigation paths.
                                    </p>
                                </div>
                            </div>
                        </section>

                        {/* Third-Party Cookies */}
                        <section className="mb-8">
                            <h2 className="text-xl font-semibold text-amber-500 mb-4">
                                3. Third-Party Cookies
                            </h2>
                            <p className="text-zinc-300">
                                Some cookies on our Website are set by third-party providers. These
                                cookies are used to deliver services such as analytics and
                                advertising.
                            </p>
                            <p className="text-zinc-300 mt-4">
                                Third-party services we may use include, but are not limited to:
                            </p>
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>Google Analytics</li>
                                <li>Social media sharing platforms (e.g., Facebook, Twitter)</li>
                            </ul>
                            <p className="text-zinc-300 mt-4">
                                We have no direct control over third-party cookies. Please refer to
                                their respective privacy policies for more information.
                            </p>
                        </section>

                        {/* How We Use Cookies */}
                        <section className="mb-8">
                            <h2 className="text-xl font-semibold text-amber-500 mb-4">
                                4. How We Use Cookies
                            </h2>
                            <p className="text-zinc-300">We use cookies to:</p>
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>Ensure the proper functioning of the Website</li>
                                <li>Remember user preferences (e.g., language, layout)</li>
                                <li>Monitor and improve website performance</li>
                                <li>Analyze user behavior to optimize content and features</li>
                            </ul>
                        </section>

                        {/* Managing Cookies */}
                        <section className="mb-8">
                            <h2 className="text-xl font-semibold text-amber-500 mb-4">
                                5. Managing Cookies
                            </h2>
                            <p className="text-zinc-300">
                                You can control or disable cookies through your browser settings.
                                Please note that disabling cookies may affect the functionality and
                                user experience of the Website.
                            </p>
                            <p className="text-zinc-300 mt-4">To manage cookies:</p>
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>
                                    Check your browser's help menu for instructions on managing
                                    cookies
                                </li>
                                <li>
                                    Use tools like optout.aboutads.info for opting out of
                                    interest-based advertising
                                </li>
                            </ul>
                        </section>

                        {/* Updates to Policy */}
                        <section className="mb-8">
                            <h2 className="text-xl font-semibold text-amber-500 mb-4">
                                6. Updates to This Policy
                            </h2>
                            <p className="text-zinc-300">
                                We may update this Cookie Policy from time to time to reflect
                                changes in our practices or legal requirements. Any updates will be
                                posted on this page with the effective date.
                            </p>
                        </section>

                        {/* Contact Information */}
                        <section className="mb-8">
                            <h2 className="text-xl font-semibold text-amber-500 mb-4">
                                7. Contact Us
                            </h2>
                            <p className="text-zinc-300">
                                If you have any questions or concerns about our Cookie Policy,
                                please contact us at:
                            </p>
                            <ul className="list-disc pl-6 text-zinc-300 mt-4">
                                <li>
                                    Email:{" "}
                                    <a
                                        href="mailto:support@bidcoin.live"
                                        className="text-amber-500 hover:text-amber-400 transition-colors"
                                    >
                                        support@bidcoin.live
                                    </a>
                                </li>
                                <li>
                                    Website:{" "}
                                    <Link
                                        to="/"
                                        className="text-amber-500 hover:text-amber-400 transition-colors"
                                    >
                                        bidcoin.live
                                    </Link>
                                </li>
                            </ul>
                        </section>
                    </div>
                </main>
            </div>
        </>
    );
};

export default CookiePolicy;

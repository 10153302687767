export function formatNumberWithCommas(value) {
    if (value == null) {
        return "0";
    }

    // Convert to string if it's not already
    const stringValue = typeof value === "string" ? value : value.toString();
    // Keep only 2 decimal places
    const trimmedValue = stringValue.match(/^\d*\.?\d{0,2}/)[0];

    // Split the string into integer and decimal parts
    const [integerPart, decimalPart] = trimmedValue.split(".");

    // Add commas to the integer part
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Combine integer and decimal parts (if exists)
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
}

import "./CookieConsentManager.css";
import React, { useState, useEffect } from "react";
import {
    getCookieConsentValue,
    resetCookieConsentValue
} from "react-cookie-consent";
import { useAuth } from "../../Services/AuthContext";

/**
 *
 */
const CookieConsentManager = () => {
    const { user, error, logout } = useAuth();
    const [consent, setConsent] = useState(
        getCookieConsentValue("yourCookieConsent")
    );

    /**
     *
     */
    const handleRevokeConsent = async () => {
        await logout();

        resetCookieConsentValue("yourCookieConsent");
        setConsent(false);

        document.cookie =
      "authCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie =
      ".AspNetCore.Cookies=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        alert(
            "Your consent has been revoked. The page will reload to apply changes."
        );

        //// Add a 2-second delay before reloading the page
        window.location.reload();
    // setTimeout(() => {
    // }, 2000);
    };

    useEffect(() => {
        setConsent(getCookieConsentValue("yourCookieConsent"));
    }, []);

    return (
        <div className="cookie-consent-manager bg-gray-800 rounded-lg p-4 flex justify-between items-center mb-2">
            <div>
                <p className="text-white">
          Cookies consent status: {consent ? "Consented" : "Not Consented"}
                </p>
            </div>
            {consent && (
                <div className="text-right">
                    <button
                        onClick={handleRevokeConsent}
                        className="revoke-consent-button bg-red-500 hover:bg-red-600 px-3 py-1 rounded text-white">
            Revoke Consent
                    </button>
                </div>
            )}
        </div>
    );
};

export default CookieConsentManager;

import { createAlchemyWeb3 } from "@alch/alchemy-web3";
// import { AlchemyProvider } from "ethers";
import { parseUnits, formatUnits, isAddress } from "ethers";
import contractABI from "../assets/abis/v4/NewAuction.json";
import tetherTokenABI from "../assets/abis/v4/CUSDT.json";
import bidCoinABI from "../assets/abis/v4/BidCoin.json";
import instance from "./AxiosConfig";
import { toast } from "react-toastify";
import { getAppKitProvider, getAppKitAccount } from "./AlchemyProvider";

const tetherTokenAddress = process.env.REACT_APP_CUSDT_ADDRESS;
const bidCoinAddress = process.env.REACT_APP_BIDCOIN_ADDRESS;

const alchemyKey = "wss://arb-mainnet.g.alchemy.com/v2/z3GTWUvrqDHlJnoPCAfcAihbeVNJFkzg";
const web3 = createAlchemyWeb3(alchemyKey);

export const tetherTokenContract = new web3.eth.Contract(tetherTokenABI.abi, tetherTokenAddress);

export const bidCoinContract = new web3.eth.Contract(bidCoinABI.abi, bidCoinAddress);
export const initializeAuctionContract = (contractAddress) => {
    return new web3.eth.Contract(contractABI.abi, contractAddress);
};

const getWalletAddress = async () => {
    const account = await getAppKitAccount();
    return account?.address || "0x0";
};

export const loadAuctionData = async ({ address }) => {
    const auctionContract = initializeAuctionContract(address);
    try {
        const auctionState = await auctionContract.methods.getAuctionState().call();
        const discountFee = await auctionContract.methods.discountFee().call();
        const pool = await auctionContract.methods.pool().call();

        /**
         *
         */
        const safeFormatUnits = (value, decimals) => {
            try {
                return Number(formatUnits(value || "0", decimals)).toString();
            } catch (error) {
                console.error("Error formatting units:", error);

                return "0";
            }
        };

        const auctionData = {
            //? Handled user balances in the next block
            // usdtBalance: safeFormatUnits(usdtBalance, 6),
            // bidBalance: safeFormatUnits(bidBalance, 18),
            address: address,

            assetDelivered: auctionState._assetDelivered,

            assetDescription: auctionState._assetDescription,

            assetLocation: auctionState._assetLocation,

            assetType: auctionState._assetType,

            auctionEndTime: auctionState._auctionEndTime,

            auctionEnded: auctionState._auctionEnded,

            currentBidPrice: auctionState._currentBidPrice,

            discountFee: auctionState._discountFee,

            rewardAssetPurchased: auctionState._rewardAssetPurchased,
            discountFee: discountFee,

            fomoPhaseActive: auctionState._fomoPhaseActive,

            ownerConfirmedDelivery: auctionState._ownerConfirmedDelivery,

            rewardAssetAddress: auctionState._rewardAssetAddress.toLowerCase(),

            rewardAssetPrice: safeFormatUnits(auctionState._rewardAssetPrice, 6),

            rewardAssetQty: safeFormatUnits(auctionState._rewardAssetQty, 18),

            totalProfit: auctionState._totalProfit,

            winnerConfirmedDelivery: auctionState._winnerConfirmedDelivery,

            winningBidder: auctionState._winningBidder.toLowerCase(), // Use the passed address

            rewardAssetPurchased: auctionState._rewardAssetPurchased,
            pool: pool,
        };
        console.log("🚀 ~ loadAuctionData ~ auctionData:", auctionData);
        const userAddress = localStorage.getItem("userAddress");
        if (isAddress(userAddress) == true) {
            const usdtBalance = await tetherTokenContract.methods.balanceOf(userAddress).call();
            const bidBalance = await bidCoinContract.methods.balanceOf(userAddress).call();

            auctionData.usdtBalance = safeFormatUnits(usdtBalance, 6);
            auctionData.bidBalance = safeFormatUnits(bidBalance, 18);
        }

        return auctionData;
    } catch (error) {
        console.error("Error loading auction data:", error);

        return {
            status: "😥 " + error.message,
        };
    }
};

/**
 *
 */
export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                address: addressArray[0],
                status: "",
            };

            return obj;
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                    <p>
                        {" "}
                        {/* 🦊{" "} */}
                        <a target="_blank" href={"https://metamask.io/download"}>
                            {/* You must install Metamask, a virtual Ethereum wallet, in your browser. */}
                        </a>
                    </p>
                </span>
            ),
        };
    }
};

/**
 *
 */
export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    status: "",
                };
            } else {
                return {
                    address: "",
                    status: "🦊 Connect to Metamask using the top right button.",
                };
            }
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                    <p>
                        {" "}
                        {/* 🦊{" "} */}
                        <a target="_blank" href={"https://metamask.io/download"}>
                            {/* You must install Metamask, a virtual Ethereum wallet, in your browser. */}
                        </a>
                    </p>
                </span>
            ),
        };
    }
};

/**
 *
 */
export const placeBidHandler = async (userAddress, contractAddress) => {
    const provider = window.ethereum;
    if (!provider || !userAddress) {
        return {
            status: "💡 Connect your wallet provider to continue.",
        };
    }

    try {
        const auctionContract = initializeAuctionContract(contractAddress);
        console.log("🚀 ~ placeBidHandler ~ auctionContract:", auctionContract);

        // Get the current discount fee
        const discountFee = await auctionContract.methods.discountFee().call();
        const usdtAmountInWei = parseUnits(discountFee.toString(), 6);
        console.log("🚀 ~ placeBidHandler ~ usdtAmountInWei:", usdtAmountInWei);

        //?Test Mint
        // const testMint = await tetherTokenContract.methods
        //   .transfer(userWallet, usdtAmountInWei)
        //   .call();
        //?Test Mint
        // Check allowance
        const currentAllowance = await tetherTokenContract.methods
            .allowance(userAddress, contractAddress)
            .call();
        // const placeBid = await auctionContract.methods.placeBid().call();
        // console.log("🚀 ~ placeBidHandler ~ placeBid:", placeBid);
        if (BigInt(currentAllowance) < BigInt(usdtAmountInWei)) {
            // Approve spending of USDT only if the current allowance is insufficient
            const approveTransactionParameters = {
                data: tetherTokenContract.methods
                    .approve(contractAddress, usdtAmountInWei)
                    .encodeABI(),
                from: userAddress,
                to: tetherTokenAddress,
            };
            console.log(
                "🚀 ~ placeBidHandler ~ approveTransactionParameters:",
                approveTransactionParameters
            );

            const approveTxHash = await provider.request({
                method: "eth_sendTransaction",
                params: [approveTransactionParameters],
            });
            console.log("🚀 ~ placeBidHandler ~ approveTxHash:", approveTxHash);

            // Wait for the approval transaction to be mined
            await new Promise((resolve) => {
                /**
                 *
                 */
                const checkTx = async () => {
                    const receipt = await web3.eth.getTransactionReceipt(approveTxHash);
                    if (receipt) {
                        resolve();
                    } else {
                        setTimeout(checkTx, 1000); // Check again after 1 second
                    }
                };
                checkTx();
            });
        } else {
            console.log("Sufficient allowance already exists. Skipping approval.");
        }

        // Place the bid
        const bidTransactionParameters = {
            data: auctionContract.methods.placeBid().encodeABI(),
            from: userAddress,
            to: contractAddress,
        };

        const bidTxHash = await provider.request({
            method: "eth_sendTransaction",
            params: [bidTransactionParameters],
        });
        console.log("🚀 ~ placeBidHandler ~ bidTxHash:", bidTxHash);

        const auctionState = await auctionContract.methods.getAuctionState().call();

        /**
         *
         */
        const safeFormatUnits = (value, decimals) => {
            try {
                return Number(formatUnits(value || "0", decimals)).toString();
            } catch (error) {
                console.error("Error formatting units:", error);

                return "0";
            }
        };
        const pool = await auctionContract.methods.pool().call();

        const auctionData = {
            //? Handled user balances in the next block
            // usdtBalance: safeFormatUnits(usdtBalance, 6),
            // bidBalance: safeFormatUnits(bidBalance, 18),
            address: contractAddress,

            assetDelivered: auctionState._assetDelivered,

            assetDescription: auctionState._assetDescription,

            assetLocation: auctionState._assetLocation,

            assetType: auctionState._assetType,

            auctionEndTime: auctionState._auctionEndTime,

            auctionEnded: auctionState._auctionEnded,

            currentBidPrice: auctionState._currentBidPrice,

            discountFee: auctionState._discountFee,

            discountFee: discountFee,

            fomoPhaseActive: auctionState._fomoPhaseActive,
            rewardAssetPurchased: auctionState._rewardAssetPurchased,

            ownerConfirmedDelivery: auctionState._ownerConfirmedDelivery,

            rewardAssetAddress: auctionState._rewardAssetAddress.toLowerCase(),

            rewardAssetPrice: safeFormatUnits(auctionState._rewardAssetPrice, 6),

            rewardAssetQty: safeFormatUnits(auctionState._rewardAssetQty, 18),

            totalProfit: auctionState._totalProfit,

            winnerConfirmedDelivery: auctionState._winnerConfirmedDelivery,

            winningBidder: auctionState._winningBidder.toLowerCase(), // Use the passed address

            rewardAssetPurchased: auctionState._rewardAssetPurchased,
            pool: pool,
        };

        const usdtBalance = await tetherTokenContract.methods.balanceOf(userAddress).call();
        const bidBalance = await bidCoinContract.methods.balanceOf(userAddress).call();

        auctionData.usdtBalance = safeFormatUnits(usdtBalance, 6);
        auctionData.bidBalance = safeFormatUnits(bidBalance, 18);

        return {
            data: auctionData,
            status: true,
        };
    } catch (error) {
        console.error("Error placing bid: " + error);
        throw error;
    }
};

/**
 *
 */
export const claimRewardHandler = async (userAddress, contractAddress) => {
    const userWallet = userAddress;
    if (!window.ethereum || userAddress === null) {
        return {
            status: "💡 Connect your wallet provider to continue.",
        };
    }
    const safeFormatUnits = (value, decimals) => {
        try {
            return Number(formatUnits(value || "0", decimals)).toString();
        } catch (error) {
            console.error("Error formatting units:", error);

            return "0";
        }
    };
    try {
        const auctionContract = initializeAuctionContract(contractAddress);
        // console.log("🚀 ~ claimRewardHandler ~ auctionContract:", auctionContract);
        const claimRewardTxHash = await auctionContract.methods
            .endAuction()
            .send({ from: userWallet });
        console.log("🚀 ~ claimRewardHandler ~ claimRewardTxHash:", claimRewardTxHash);
        // Return the transaction hash

        const apiKey = process.env.REACT_APP_ARBISCAN_API_KEY; // Replace with your Arbiscan API key
        const response = await fetch(
            `https://api.arbiscan.io/api?module=proxy&action=eth_getTransactionReceipt&txhash=${claimRewardTxHash.transactionHash}&apikey=${apiKey}`
        );
        const data = await response.json();

        if (data.result) {
            const fromAddress = data.result.from; // Address that made the call
            const logs = data.result.logs; // Logs for emitted events
            try {
                const auctionState = await auctionContract.methods.getAuctionState().call();
                const auctionDiscountFee = await auctionContract.methods.discountFee().call();
                const auctionEndLog = {
                    address: contractAddress,
                    auctionAddress: contractAddress,
                    discountFee: auctionDiscountFee, // Required
                    auctionWinner: auctionState._winningBidder.toLowerCase(), // Required
                };
                instance.post("/api/v1/whitelist/end-auction", {
                    fromAddress: fromAddress,
                    auctionEndLog: auctionEndLog,
                    logs: "logs",
                });
                // console.log("From Address:", fromAddress);
                // console.log("Logs:", logs);
                toast.success("You've earned bonus BidCoins!");
            } catch (error) {
                console.error("Error sending logs to server:", error);
            }
        } else {
            console.error("Transaction not found or error:", data);
        }
        return {
            status: true,
            txHash: claimRewardTxHash.transactionHash, // Return the transaction hash
        };
    } catch (error) {
        console.error("Error claiming reward: " + error);

        return {
            status: "😥 " + error.message,
        };
    }
};

/**
 *
 */
export const burnBidHandler = async (userAddress, contractAddress) => {
    const userWallet = userAddress;
    if (!window.ethereum || userAddress === null) {
        return {
            status: "💡 Connect your wallet provider to continue.",
        };
    }

    try {
        const auctionContract = initializeAuctionContract(contractAddress);
        // console.log("🚀 ~ placeBidHandler ~ auctionContract:", auctionContract);

        // Get the current discount fee
        const discountFee = await auctionContract.methods.discountFee().call();
        // console.log("🚀 ~ burnBidHandler ~ discountFee:", discountFee);
        const usdtPerBidCoin = await auctionContract.methods.USDT_PER_BIDCOIN().call();
        // console.log("🚀 ~ burnBidHandler ~ usdtPerBidCoin:", usdtPerBidCoin);
        const bidAmount = discountFee / usdtPerBidCoin;
        // console.log("🚀 ~ burnBidHandler ~ bidAmount:", bidAmount);
        //USDT_PER_BIDCOIN
        const usdtAmountInWei = parseUnits(bidAmount.toString(), 18);
        // console.log("🚀 ~ burnBidHandler ~ usdtAmountInWei:", usdtAmountInWei);

        // Check allowance
        const currentAllowance = await bidCoinContract.methods
            .allowance(userWallet, contractAddress)
            .call();

        if (BigInt(currentAllowance) < BigInt(usdtAmountInWei)) {
            // Approve spending of USDT only if the current allowance is insufficient
            const approveTransactionParameters = {
                data: bidCoinContract.methods.approve(contractAddress, usdtAmountInWei).encodeABI(),
                from: userWallet,
                to: bidCoinAddress,
            };
            // console.log(
            //   "🚀 ~ burnBidHandler ~ approveTransactionParameters:",
            //   approveTransactionParameters
            // );

            const approveTxHash = await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [approveTransactionParameters],
            });
            // console.log("🚀 ~ burnBidHandler ~ approveTxHash:", approveTxHash);

            // Wait for the approval transaction to be mined
            await new Promise((resolve) => {
                /**
                 *
                 */
                const checkTx = async () => {
                    const receipt = await web3.eth.getTransactionReceipt(approveTxHash);
                    if (receipt) {
                        resolve();
                    } else {
                        setTimeout(checkTx, 1000); // Check again after 1 second
                    }
                };
                checkTx();
            });
        } else {
            console.log("Sufficient allowance already exists. Skipping approval.");
        }

        // Place the bid
        const bidTransactionParameters = {
            data: auctionContract.methods.placeBidWithBidCoin().encodeABI(),
            from: userWallet,
            to: contractAddress,
        };

        const bidTxHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [bidTransactionParameters],
        });
        // console.log("🚀 ~ burnBidHandler ~ bidTxHash:", bidTxHash);

        const auctionState = await auctionContract.methods.getAuctionState().call();

        /**
         *
         */
        const safeFormatUnits = (value, decimals) => {
            try {
                return Number(formatUnits(value || "0", decimals)).toString();
            } catch (error) {
                console.error("Error formatting units:", error);

                return "0";
            }
        };
        const pool = await auctionContract.methods.pool().call();

        const auctionData = {
            //? Handled user balances in the next block
            // usdtBalance: safeFormatUnits(usdtBalance, 6),
            // bidBalance: safeFormatUnits(bidBalance, 18),
            address: contractAddress,

            assetDelivered: auctionState._assetDelivered,

            assetDescription: auctionState._assetDescription,

            assetLocation: auctionState._assetLocation,

            assetType: auctionState._assetType,

            auctionEndTime: auctionState._auctionEndTime,

            auctionEnded: auctionState._auctionEnded,

            currentBidPrice: auctionState._currentBidPrice,

            discountFee: auctionState._discountFee,

            discountFee: discountFee,

            fomoPhaseActive: auctionState._fomoPhaseActive,

            ownerConfirmedDelivery: auctionState._ownerConfirmedDelivery,

            rewardAssetAddress: auctionState._rewardAssetAddress.toLowerCase(),
            rewardAssetPurchased: auctionState._rewardAssetPurchased,
            rewardAssetPrice: safeFormatUnits(auctionState._rewardAssetPrice, 6),

            rewardAssetQty: safeFormatUnits(auctionState._rewardAssetQty, 18),

            totalProfit: auctionState._totalProfit,

            winnerConfirmedDelivery: auctionState._winnerConfirmedDelivery,

            winningBidder: auctionState._winningBidder.toLowerCase(), // Use the passed address

            rewardAssetPurchased: auctionState._rewardAssetPurchased,
            pool: pool,
        };
        const userAddress = await getWalletAddress();
        if (isAddress(userAddress) == true) {
            const usdtBalance = await tetherTokenContract.methods.balanceOf(userAddress).call();
            const bidBalance = await bidCoinContract.methods.balanceOf(userAddress).call();

            auctionData.usdtBalance = safeFormatUnits(usdtBalance, 6);
            auctionData.bidBalance = safeFormatUnits(bidBalance, 18);
        }

        return {
            data: auctionData,
            status: true,
        };
    } catch (error) {
        console.error("Error burning bid: " + error);

        return {
            status: "😥 " + error.message,
        };
    }
};

import React, { useEffect, useState } from "react";
import { formatUnits } from "viem";
import { bidCoinContract, tetherTokenContract } from "../../Services/AlchemyTetherInteract";
import { formatNumberWithCommas } from "../../utils/formatters";
import { LeaveSiteModal } from "./Modals/LeaveSiteModal";
import { useLeaveSiteModal } from "../../Services/LeaveSiteModalContext";
import BidCoinLogo from "../../assets/currentLogo.png";
import { useAppKit, useAppKitAccount } from "@reown/appkit/react";

const Balance = () => {
    const { address, isConnected } = useAppKitAccount();
    const tetherContract = tetherTokenContract;
    const bidContract = bidCoinContract;
    const [usdtBalance, setUsdtBalance] = useState(0);
    const [bidBalance, setBidBalance] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [urlToOpen, setUrlToOpen] = useState("");
    const { openModal } = useLeaveSiteModal();

    useEffect(() => {
        localStorage.setItem("userAddress", address);
        const intervalId = setInterval(() => {
            if (isConnected) {
                console.log("Fetching balances for address:", address);
                fetchBalances();
            }
        }, 30000); // Update every 3 seconds

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [address, isConnected]);
    const handleTokenClick = (address) => {
        openModal(`https://arbiscan.io/token/${address}`);
    };

    const handleConfirm = () => {
        window.open(urlToOpen, "_blank");
        setModalOpen(false);
    };

    const fetchBalances = async () => {
        try {
            if (!address) return;
            setLoading(true);
            const usdtBalance = await tetherTokenContract.balanceOf(address);
            const bidBalance = await bidCoinContract.balanceOf(address);
            // console.log("USDT Balance:", usdtBalance);
            // console.log("BID Balance:", bidBalance);
            setUsdtBalance(formatNumberWithCommas(formatUnits(usdtBalance, 6)));
            setBidBalance(formatNumberWithCommas(formatUnits(bidBalance, 18)));
            setLoading(false);
        } catch (error) {
            console.error("Error fetching balances:", error);
            setError(error.message);
            setLoading(false);
        }
        setLoading(false);
    };

    if (!isConnected) return <w3m-button size="sm" balance="hide"></w3m-button>;
    return (
        <div className="flex gap-3 items-center">
            <LeaveSiteModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleConfirm}
            />
            <div
                className="px-1 py-1.5 rounded-lg cursor-pointer"
                onClick={() => handleTokenClick(tetherContract.options.address)}
            >
                <span className="text-white text-sm font-mono whitespace-nowrap flex flex-row items-center">
                    <span className="mr-1">💵</span>${`${formatNumberWithCommas(usdtBalance)}`}
                </span>
            </div>
            <div
                className="px-1 py-1.5 rounded-lg cursor-pointer"
                onClick={() => handleTokenClick(bidContract.options.address)}
            >
                <span className="text-white text-sm font-mono whitespace-nowrap flex flex-row items-center">
                    <img src={BidCoinLogo} alt="BidCoin Logo" className="w-4 h-4 mr-1" />
                    {formatNumberWithCommas(bidBalance)}
                </span>
            </div>
        </div>
    );
};

export default Balance;

import React, { useEffect } from "react";
// import { Integrations } from "@sentry/tracing";
// import { SIWEPopup } from "../Components/Common/One-Click-Auth/UpdatedLogin";
import { createAppKit } from "@reown/appkit/react";
import { arbitrum, mainnet, AppKitNetwork } from "@reown/appkit/networks";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import { WagmiProvider } from "wagmi";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "../Services/AuthContext";
import { StakingProvider } from "../Services/StakingContext";
import { LoadingProvider } from "./LoadingContext";
import CustomAppRouter from "./CustomAppRouter";
import { createSIWE } from "./Common/One-Click-Auth/siweConfig";
import OnboardingFlow from "./Onboarding/OnboardingFlow";
import { LeaveSiteModalProvider } from "../Services/LeaveSiteModalContext";
import { NotificationProvider } from "../Services/NotificationContext";
import { WalletStateManager } from "../Services/AlchemyProvider";
import { infoMessageService } from "../Services/InfoMessageService";
import { HelmetProvider } from "react-helmet-async";

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.reown.com
const projectId = process.env.REACT_APP_REOWN_PROJECT_ID;
if (!projectId) {
    throw new Error("Project ID is undefined");
}

// 2. Create metadata
const metadata = {
    description: "Decentralized Penny Auction, the most advanced bidding platform of Web3.",
    icons: ["https://api.bidcoin.live/logo512.png"],
    name: "Bidcoin",
    url: "https://bidcoin.live/",
};

// 3. Set the networks
export const chains = [arbitrum];

// 4. Create Wagmi Adapter
const wagmiAdapter = new WagmiAdapter({
    networks: chains,
    networks: chains,
    projectId,
    ssr: true,
});

// 5. Create a SIWE configuration object
export const siweConfig = createSIWE(chains);

// 6. Create modal
export const modal = createAppKit({
    adapters: [wagmiAdapter],
    features: {
        email: true,
        emailShowWallets: true,

        // default to true
        legalCheckbox: true,
        // default to true
        socials: ["google", "x", "github", "discord", "apple", "facebook"],
    },
    metadata,
    networks: chains,
    projectId,
    siweConfig: siweConfig,
    themeMode: "dark",
    themeVariables: {
        // "--w3m-color-mix": "#00DCFF",
        // "--w3m-color-mix-strength": 20,

        "--w3m-accent-color": "#f59e0b",
        "--w3m-accent": "#f59e0b",
    },
});
/**
 *
 */
const AppRouter = () => {
    useEffect(() => {
        infoMessageService.initializeMessages();
        localStorage.setItem("welcomePopupShown", "false");
    }, []);
    /**
     *
     */
    const handleConnect = () => {
        // Implement your wallet connection logic here
        console.log("Connecting wallet...");
    };

    return (
        <div className="App">
            <HelmetProvider>
                <WagmiProvider config={wagmiAdapter.wagmiConfig}>
                    <QueryClientProvider client={queryClient}>
                        <LoadingProvider>
                            <WalletStateManager>
                                <LeaveSiteModalProvider>
                                    <AuthProvider>
                                        <NotificationProvider>
                                            <StakingProvider>
                                                <OnboardingFlow />
                                                <CustomAppRouter />
                                            </StakingProvider>
                                        </NotificationProvider>
                                    </AuthProvider>
                                </LeaveSiteModalProvider>
                            </WalletStateManager>
                        </LoadingProvider>
                    </QueryClientProvider>
                </WagmiProvider>
            </HelmetProvider>
            //{" "}
        </div>
    );
};

export default AppRouter;

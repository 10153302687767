import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const PrivacyCompliance = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Privacy & Compliance - BidCoin</title>
                <meta name="description" content="BidCoin's Privacy and Compliance Statement" />
            </Helmet>

            <div className="min-h-screen bg-gradient-to-br from-zinc-900 via-zinc-900 to-zinc-800/50 pt-16">
                <main className="max-w-4xl mx-auto px-4 py-16">
                    <h1 className="text-3xl font-bold text-zinc-100 mb-8">
                        No Custody and No KYC Compliance Statement
                    </h1>

                    {/* Platform Purpose */}
                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-amber-500 mb-4">
                            1. Platform Purpose
                        </h2>
                        <div className="prose prose-invert">
                            <p className="text-zinc-300">
                                BidCoin is a decentralized auction platform that facilitates
                                blockchain-based auctions for both physical and digital items. All
                                interactions on the platform are powered by transparent,
                                self-executing smart contracts deployed on the blockchain.
                            </p>
                            <p className="text-zinc-300">
                                BidCoin acts solely as a facilitator of auctions and does not act as
                                a custodian of user funds, nor does it verify or validate user
                                identities.
                            </p>
                        </div>
                    </section>

                    {/* Non-Custodial Operations */}
                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-amber-500 mb-4">
                            2. Non-Custodial Operations
                        </h2>
                        <div className="prose prose-invert">
                            <p className="text-zinc-300">
                                BidCoin is a non-custodial platform. This means:
                            </p>
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>
                                    The platform does not store, manage, or have access to user
                                    funds, bid fees, or auction payments.
                                </li>
                                <li>
                                    All financial transactions are conducted directly on the
                                    blockchain through smart contracts.
                                </li>
                                <li>
                                    Users maintain full control over their wallets and private keys.
                                </li>
                            </ul>
                        </div>
                    </section>

                    {/* Data Collection */}
                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-amber-500 mb-4">
                            3. Data Collection for Physical Goods
                        </h2>
                        <div className="prose prose-invert">
                            <p className="text-zinc-300">
                                For auctions involving physical goods, BidCoin collects the minimum
                                information required to facilitate delivery. This includes:
                            </p>
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>Full name</li>
                                <li>Delivery address</li>
                                <li>Contact phone number</li>
                                <li>Email (for shipment tracking or communication)</li>
                            </ul>
                            <p className="text-zinc-300 mt-4">How We Handle Data:</p>
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>
                                    This data is collected solely for the purpose of order
                                    fulfillment and passed securely to third-party logistics
                                    providers for delivery.
                                </li>
                                <li>
                                    BidCoin does not verify or store this data beyond what is
                                    necessary for operational purposes.
                                </li>
                                <li>
                                    Users are responsible for ensuring the accuracy of the
                                    information provided.
                                </li>
                            </ul>
                        </div>
                    </section>

                    {/* No KYC Requirement */}
                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-amber-500 mb-4">
                            4. No KYC Requirement
                        </h2>
                        <div className="prose prose-invert">
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>
                                    BidCoin does not require Know Your Customer (KYC) verification.
                                </li>
                                <li>
                                    We do not collect government-issued IDs, proof of address, or
                                    other personal verification documents.
                                </li>
                                <li>
                                    Delivery data for physical goods is not used for identity
                                    verification or checked against any third-party databases.
                                </li>
                                <li>
                                    Users interact with the blockchain through their own wallets and
                                    retain full anonymity within the platform, except when providing
                                    delivery information for physical goods.
                                </li>
                            </ul>
                        </div>
                    </section>

                    {/* Cryptocurrency-Only Transactions */}
                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-amber-500 mb-4">
                            5. Cryptocurrency-Only Transactions
                        </h2>
                        <div className="prose prose-invert">
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>
                                    All auctions and bidding activities on BidCoin are conducted
                                    exclusively in cryptocurrency.
                                </li>
                                <li>
                                    Bid fees and auction purchases are processed directly via
                                    blockchain smart contracts, ensuring a transparent,
                                    decentralized process.
                                </li>
                                <li>
                                    BidCoin does not handle fiat payments or facilitate
                                    fiat-to-crypto conversions. Users are responsible for any
                                    currency conversion through external services.
                                </li>
                            </ul>
                        </div>
                    </section>

                    {/* Decentralized and Transparent */}
                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-amber-500 mb-4">
                            6. Decentralized and Transparent
                        </h2>
                        <div className="prose prose-invert">
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>
                                    BidCoin operates as a decentralized platform, leveraging
                                    blockchain technology to ensure transparency, security, and
                                    trust.
                                </li>
                                <li>
                                    Smart contracts autonomously execute all auction activities,
                                    from bid placement to final purchase.
                                </li>
                                <li>
                                    All bidding and payment activity is publicly verifiable on the
                                    blockchain.
                                </li>
                                <li>
                                    BidCoin does not intervene in the bidding process or control the
                                    outcome of any auction.
                                </li>
                            </ul>
                        </div>
                    </section>

                    {/* Delivery of Physical Goods */}
                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-amber-500 mb-4">
                            7. Delivery of Physical Goods
                        </h2>
                        <div className="prose prose-invert">
                            <p className="text-zinc-300">
                                For physical goods purchased through the platform:
                            </p>
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>
                                    Delivery data is collected and securely transmitted to
                                    third-party logistics providers for fulfillment.
                                </li>
                                <li>
                                    BidCoin is not responsible for delays, losses, or damages caused
                                    by third-party services.
                                </li>
                                <li>
                                    The user accepts full responsibility for providing accurate
                                    delivery details and understands that BidCoin does not verify
                                    this information.
                                </li>
                            </ul>
                        </div>
                    </section>

                    {/* Privacy and Data Protection */}
                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-amber-500 mb-4">
                            8. Privacy and Data Protection
                        </h2>
                        <div className="prose prose-invert">
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>BidCoin is committed to protecting user privacy.</li>
                                <li>
                                    Delivery data is stored temporarily and only for the duration
                                    necessary to facilitate order fulfillment.
                                </li>
                                <li>
                                    We do not use delivery data for marketing, identity
                                    verification, or any purpose other than order fulfillment.
                                </li>
                            </ul>
                        </div>
                    </section>

                    {/* User Responsibility */}
                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-amber-500 mb-4">
                            9. User Responsibility
                        </h2>
                        <div className="prose prose-invert">
                            <p className="text-zinc-300">
                                Users participating on the BidCoin platform acknowledge and accept
                                the following:
                            </p>
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>
                                    They are fully responsible for safeguarding their wallets,
                                    private keys, and cryptocurrency assets.
                                </li>
                                <li>
                                    Users must ensure compliance with the laws applicable in their
                                    respective jurisdictions.
                                </li>
                                <li>
                                    Users must provide accurate delivery information for physical
                                    goods and accept responsibility for any issues arising from
                                    incorrect details.
                                </li>
                            </ul>
                        </div>
                    </section>

                    {/* Disclaimer of Liability */}
                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-amber-500 mb-4">
                            10. Disclaimer of Liability
                        </h2>
                        <div className="prose prose-invert">
                            <p className="text-zinc-300">
                                BidCoin provides a decentralized platform for blockchain-based
                                auctions and is not liable for:
                            </p>
                            <ul className="list-disc pl-6 text-zinc-300">
                                <li>
                                    Errors or losses caused by user negligence, including the loss
                                    of private keys or incorrect wallet addresses.
                                </li>
                                <li>
                                    Delays or failures in third-party services, including delivery
                                    providers.
                                </li>
                                <li>
                                    Regulatory obligations in specific jurisdictions, which are the
                                    sole responsibility of the user.
                                </li>
                            </ul>
                        </div>
                    </section>

                    {/* Future Adjustments */}
                    <section className="mb-8">
                        <h2 className="text-xl font-semibold text-amber-500 mb-4">
                            11. Future Adjustments
                        </h2>
                        <div className="prose prose-invert">
                            <p className="text-zinc-300">
                                As the regulatory landscape for blockchain technology evolves,
                                BidCoin reserves the right to implement additional compliance
                                measures, including KYC, to meet future operational or
                                jurisdictional requirements. Any changes will be communicated
                                transparently to users.
                            </p>
                        </div>
                    </section>
                </main>
            </div>
        </>
    );
};

export default PrivacyCompliance;

import React, { useState, useEffect, useCallback } from "react";
import { Award, Zap, TrendingUp, Clock, LogOut } from "lucide-react";
import axios from "axios";
import { useAppKitProvider, useAppKitAccount } from "@reown/appkit/react";
import { BlockchainOverlay } from "../Common/LoadingScreen";
import {
    stakingContract,
    connectWallet,
    loadUserData,
    getCurrentWalletConnected,
    checkAndSwitchChain,
} from "../../Services/AlchemyTetherInteract";
import instance from "../../Services/AxiosConfig";
//!New Work
import CookieConsentManager from "../Cookies/CookieConsentManager";
import { useAuth } from "../../Services/AuthContext";
import useSound from "../../Services/SoundService";
import { getAppKitAccount, runAlchemy } from "../../Services/AlchemyProvider";
import { siweConfig } from "../Common/One-Click-Auth/siweConfig";
import AchievementsDisplay from "./Achievements";
import { formatNumberWithCommas } from "../../utils/formatters";
import { Helmet } from "react-helmet-async";

import truncateEthAddress from "truncate-eth-address";
import { DualUserBalanceDisplay } from "../../Feature/Profile/BalanceDisplay";
import UserSettings from "./UserSettings";
import { useNavigate, useLocation } from "react-router-dom";
import Balance from "../Common/Balance";

/**
 *
 */
const UserProfileRewards = () => {
    const { walletProvider } = useAppKitProvider();
    const { address } = useAppKitAccount();
    const { user, error, logout } = useAuth();
    const [achievements, setAchievements] = useState([]);
    const [userData, setUserData] = useState("");
    const [walletAddress, setWalletAddress] = useState("");
    const [status, setStatus] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showBalances, setShowBalances] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Get the active tab from the URL path
    const getActiveTabFromPath = () => {
        const path = location.pathname.split("/").pop();
        if (["settings", "achievements"].includes(path)) {
            return path;
        }
        return "overview";
    };

    // Initialize activeTab based on current URL
    const [activeTab, setActiveTab] = useState(getActiveTabFromPath());

    // Update activeTab when URL changes
    useEffect(() => {
        setActiveTab(getActiveTabFromPath());
    }, [location.pathname]);

    // Rest of your existing state declarations...

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        if (tab === "overview") {
            navigate("/profile");
        } else {
            navigate(`/profile/${tab}`);
        }
        playTabSound();
    };
    /**
     *
     */
    async function onSignMessage({ accounts }) {
        if (localStorage.getItem("sign") && localStorage.getItem("sign") !== false) {
            return;
        }

        if (localStorage.getItem("sign") != false) {
            return;
        }

        if (localStorage.getItem("sign") !== false) {
            return;
        }
        // get nonce, uri, domain, date, statement
        const nonce = await instance.get("/api/Siwe/nonce");

        const uri = window.location.origin;
        const domain = window.location.host;
        const date = new Date().toISOString();
        const walletOwnershipSignature =
            "Sign in to BidCoin to start bidding in Web3!\nPlease sign this message to confirm you have access to this wallet address. \nNonce: " +
            nonce +
            "\nUri: " +
            uri +
            "\nDomain: " +
            domain +
            "\nDate: " +
            date;
        try {
            const from = accounts[0];
            // For historical reasons, you must submit the message to sign in hex-encoded UTF-8.
            // This uses a Node.js-style buffer shim in the browser.
            const msg = `0x${Buffer.from(walletOwnershipSignature, "utf8").toString("hex")}`;
            const sign = await ethereum.request({
                method: "personal_sign",
                params: [msg, from],
            });
            if (sign) {
                localStorage.setItem("signed", true);
            }
            console.log(sign);
        } catch (err) {
            console.error(err);
            personalSign.innerHTML = `Error: ${err.message}`;
        }
    }
    //called only once
    useEffect(() => {
        /**
         *
         */
        const fetchBidCount = async () => {
            setIsLoading(true);
            runAlchemy();
            try {
                // console.log("🚀 ~ fetchBidCount ~ loadUserData");
                const data = await loadUserData();
                if (data) {
                    setUserData(data);
                    // refreshUserData();
                    // addSmartContractListener();

                    /**
                     *
                     */
                    const fetchWallet = async () => {
                        setIsLoading(true);
                        try {
                            // Step 1: Connect wallet
                            const address = localStorage.getItem("userAddress");
                            if (!address) {
                                throw new Error("Failed to connect wallet");
                            }
                            setWalletAddress(address);
                            setStatus(status);

                            // Step 2: Check and switch chain
                            await checkAndSwitchChain();

                            // Step 3: Sign personal message
                            await onSignMessage({ accounts: [address] });

                            // If all steps succeed, show balances
                            setShowBalances(true); // Wallet connected, show balances
                        } catch (error) {
                            console.error("Error in wallet connection process:", error);
                            setWalletAddress("");
                            setStatus(`Error: ${error.message}`);
                            setShowBalances(false);
                        } finally {
                            setIsLoading(false);
                        }
                    };
                    fetchWallet();
                    addWalletListener();
                    fetchAchievements();
                } else {
                    console.error("Failed to load user profile data");
                    // Handle the error case, maybe set some error state
                }
            } catch (error) {
                console.error("Error fetching user profile data:", error);
                // Handle the error case
            } finally {
                setIsLoading(false);
            }
        };

        fetchBidCount();
    }, []);
    /**
     *
     */
    async function getBalance() {
        try {
            // if (!isConnected) toast.error("User disconnected");
            // const ethersProvider = new BrowserProvider(walletProvider);
            // const signer = await ethersProvider.getSigner();
            // // The Contract object
            // const USDTContract = new Contract(
            //   tetherTokenAddress,
            //   tetherTokenABI.abi,
            //   signer
            // );
            // const USDTBalance = await USDTContract.balanceOf(address);
            // console.log(formatUnits(USDTBalance, 18));
        } catch (error) {
            console.error("Error getBalance user profile data:", error);
            // Handle the error case
        } finally {
            setIsLoading(false);
        }
    }

    const playTabSound = useSound("/sounds/tab_change.wav");

    const fetchAchievements = useCallback(async () => {
        try {
            const response = await instance.get("/api/achievements");
            // console.log("🚀 ~ fetchAchievements ~ response:", response);
            if (response.data && response.data.data) {
                // Add default values for each achievement
                const processedAchievements = response.data.data.map((achievement) => ({
                    ...achievement,
                    isUnlocked: false,
                    loading: false,
                    progress: 0,
                }));
                setAchievements(processedAchievements);
            } else {
                console.error("No achievements data in response");
            }
        } catch (error) {
            console.error("Error fetching achievements:", error);
        }
    }, []);

    /**
     *
     */
    function addSmartContractListener() {
        stakingContract.events.Staked({}, async (error, data) => {
            if (error) {
                setStatus("😥 " + error.message);
            } else {
                /**
                 * Staked
                 * (indexed address staker, [0]
                 * uint256 amount, [1]
                 */
                // setAuctionData(data.returnValues[1]);
                const res = data.returnValues;
                // console.log("Staked Event emitted by: " + res[0] + ", amount: " + res[1]);
                // setUpdatedBidsCount("");
                // setStatus("🎉 Your message has been updated!");
                initDatRefresh();
                playTabSound();
            }
        });
        stakingContract.events.UnstakeRequested({}, async (error, data) => {
            if (error) {
                setStatus("😥 " + error.message);
            } else {
                /**
                 * Staked
                 * (indexed address staker, [0]
                 * uint256 unstakeRequestTime, [1]
                 */
                // setAuctionData(data.returnValues[1]);
                const res = data.returnValues;
                // console.log(
                //     "UnstakeRequested Event emitted by: " + res[0] + ", requestTime: " + res[1]
                // );
                // setUpdatedBidsCount("");
                // setStatus("🎉 Your message has been updated!");
                initDatRefresh();
                playTabSound();
            }
        });
        stakingContract.events.RewardClaimed({}, async (error, data) => {
            if (error) {
                setStatus("😥 " + error.message);
            } else {
                /**
                 * Staked
                 * (indexed address staker, [0]
                 * uint256 unstakeAmount, [1]
                 */
                // setAuctionData(data.returnValues[1]);
                const res = data.returnValues;
                // console.log("RewardClaimed Event emitted by: " + res[0] + ", amount: " + res[1]);
                // setUpdatedBidsCount("");
                // setStatus("🎉 Your message has been updated!");
                initDatRefresh();
            }
        });
    }

    /**
     *
     */
    async function initDatRefresh() {
        const data = await loadUserData();
        if (data) {
            setUserData(data);
        }
    }

    /**
     *
     */
    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWalletAddress(accounts[0]);
                    setStatus("");
                } else {
                    setWalletAddress("");
                    setStatus("🦊 Connect to Metamask using the top right button.");
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    {/* 🦊{" "} */}
                    <a target="_blank" href={"https://metamask.io/download"}>
                        {/* You must install Metamask, a virtual Ethereum wallet, in your browser. */}
                    </a>
                </p>
            );
        }
    }

    if (error) {
        return <div className="text-red-500">Error: {error}</div>;
    }

    const userStats = [
        {
            icon: Zap,
            label: "Total Bids",
            value: userData?.totalBids || 0,
            colors: {
                icon: "text-amber-500",
                value: "text-amber-500",
            },
        },
        {
            icon: TrendingUp,
            label: "Wins",
            value: userData?.wins || 0,
            colors: {
                icon: "text-emerald-500",
                value: "text-emerald-500",
            },
        },
        {
            icon: Zap,
            label: "Total Staked",
            value: userData?.stakedAmount ?? 0,
            colors: {
                icon: "text-amber-500",
                value: "text-amber-500",
            },
        },
        {
            icon: Award,
            label: "Auctions Won",
            value: userData?.auctionsWonCount ?? 0,
            colors: {
                icon: "text-emerald-500",
                value: "text-emerald-500",
            },
        },
        {
            icon: TrendingUp,
            label: "Staking Rewards",
            value: `${userData?.rewards ?? 0} USDT`,
            colors: {
                icon: "text-rose-500",
                value: "text-rose-500",
            },
        },
        {
            icon: Clock,
            label: "Time Active",
            value: user?.joinDate
                ? Math.floor((new Date() - new Date(user?.joinDate)) / (1000 * 60 * 60 * 24)) +
                  " days"
                : "0 days",
            colors: {
                icon: "text-blue-500",
                value: "text-blue-500",
            },
        },
    ];

    return (
        <>
            <Helmet>
                <title>Profile - BidCoin</title>
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@BidCoinNews" />
                <meta name="twitter:title" content="Profile - BidCoin" />
                <meta name="twitter:description" content="Profile - BidCoin" />
                <meta name="twitter:image" content="https://bidcoin.live/favicon.ico" />
                <meta property="og:title" content="Profile - BidCoin" />
                <meta property="og:description" content="Profile - BidCoin" />
                <meta property="og:image" content="https://bidcoin.live/favicon.ico" />
                <meta property="og:url" content="https://bidcoin.live/profile" />
            </Helmet>
            <BlockchainOverlay loading={isLoading} />

            <div className="min-h-screen bg-gradient-to-br from-zinc-900 via-zinc-900 to-zinc-800/50 mt-16">
                <main className="max-w-7xl mx-auto px-4 py-16">
                    {/* Profile Header */}
                    <header className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6 mb-8">
                        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-6">
                            <div className="flex items-center gap-4">
                                <div className="relative">
                                    <div className="w-16 h-16 rounded-full bg-gradient-to-br from-amber-500 to-amber-600 flex items-center justify-center text-2xl font-bold text-zinc-900">
                                        {user?.firstName?.[0]?.toUpperCase() || walletAddress?.[0]}
                                    </div>
                                    <div className="absolute -bottom-1 -right-1 bg-emerald-500 rounded-full p-1">
                                        <Award className="w-4 h-4 text-zinc-900" />
                                    </div>
                                </div>
                                <div>
                                    <h1 className="text-2xl font-medium text-zinc-100">
                                        {user?.firstName || truncateEthAddress(walletAddress)}
                                    </h1>
                                    <p className="text-zinc-400 text-sm">Joined {user?.joinDate}</p>
                                </div>
                            </div>
                            {/* <DualUserBalanceDisplay
                                balance1={`${formatNumberWithCommas(userData?.usdtBalance)} USDT`}
                                balance2={`${formatNumberWithCommas(userData?.bidBalance)} BID`}
                            /> */}
                        </div>
                    </header>

                    <TabNavigation activeTab={activeTab} handleTabChange={handleTabChange} />
                    <TabContent
                        activeTab={activeTab}
                        userStats={userStats}
                        achievements={achievements}
                        walletAddress={walletAddress}
                    />
                    {/* Achievements Section */}
                    {/* <div className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6">
                        <h2 className="text-xl font-medium text-zinc-100 mb-6">Achievements</h2>
                        <AchievementsDisplay
                            achievements={achievements}
                            userAddress={walletAddress}
                        />
                    </div> */}

                    {/* Status Messages */}
                    {error && (
                        <div className="mt-6 p-4 bg-rose-500/10 border border-rose-500/20 rounded-xl text-rose-200 flex justify-between items-center">
                            {error}
                            <button
                                onClick={() => setError(null)}
                                className="ml-2 text-rose-200 hover:text-rose-100"
                            >
                                ✕
                            </button>
                        </div>
                    )}
                    {status && (
                        <div className="mt-6 p-4 bg-amber-500/10 border border-amber-500/20 rounded-xl text-amber-200 text-center">
                            {status}
                            <button onClick={() => setStatus(null)}>X</button>
                        </div>
                    )}
                </main>
            </div>
        </>
    );
};

/**
 *
 */
const UserProfileContent = ({ user, userData, walletAddress, usdtBalance, bidCoinBalance }) => (
    <div className="space-y-6">
        <div className="flex flex-col md:flex-row items-start md:items-center gap-6">
            <div className="flex items-center gap-4">
                <div className="w-24 h-24 bg-gradient-to-br from-amber-500 to-amber-600 rounded-full flex items-center justify-center text-2xl font-bold text-zinc-900">
                    {user.username
                        ? user.username.length > 6
                            ? user.username.slice(0, 6) + "..."
                            : user.username
                        : "User"}
                </div>
                <div>
                    <h2 className="text-2xl font-medium text-zinc-100">
                        {userData?.address ? <w3m-button /> : <w3m-button />}
                    </h2>
                    <p className="text-zinc-400">{user?.firstName || "Loading..."}</p>
                    <p className="text-sm text-zinc-500">
                        {user?.dateOfBirth ? user.dateOfBirth.split("T")[0] : "Loading..."}
                    </p>
                </div>
            </div>
            {walletAddress && walletAddress.length > 0 && (
                <div className="flex-shrink-0">
                    <UserBalanceDisplay
                        userData={userData}
                        usdtBalance={formatNumberWithCommas(userData?.usdtBalance)}
                        bidCoinBalance={formatNumberWithCommas(userData?.bidBalance)}
                    />
                </div>
            )}
        </div>
    </div>
);

/**
 *
 */
const UserBalanceDisplay = ({ userData, usdtBalance, bidCoinBalance }) => {
    const [showBalances, setShowBalances] = useState(false);

    useEffect(() => {
        if (userData && usdtBalance && bidCoinBalance) {
            setShowBalances(true);
        }
    }, [userData, usdtBalance, bidCoinBalance]);

    const parseFormattedNumber = (formattedNumber) => {
        return parseFloat(formattedNumber.replace(/,/g, ""));
    };

    return (
        <div className="space-y-4">
            <div className="bg-zinc-800/50 backdrop-blur-sm rounded-xl p-4 border border-zinc-700/50">
                <p className="text-sm text-zinc-400 mb-1">BID Balance</p>
                <p className="text-2xl font-mono text-amber-500">
                    {showBalances ? (
                        <CountUp
                            end={parseFormattedNumber(bidCoinBalance)}
                            duration={1618}
                            decimals={0}
                            suffix=" BID"
                        />
                    ) : (
                        "Loading..."
                    )}
                </p>
            </div>

            <div className="bg-zinc-800/50 backdrop-blur-sm rounded-xl p-4 border border-zinc-700/50">
                <p className="text-sm text-zinc-400 mb-1">USDT Balance</p>
                <p className="text-2xl font-mono text-emerald-500">
                    {showBalances ? (
                        <CountUp
                            end={parseFormattedNumber(usdtBalance)}
                            duration={1618}
                            decimals={0}
                            suffix=" USDT"
                        />
                    ) : (
                        "Loading..."
                    )}
                </p>
            </div>

            <div className="bg-zinc-800/50 backdrop-blur-sm rounded-xl p-4 border border-zinc-700/50">
                <p className="text-sm text-zinc-400 mb-2">
                    Referral Level{" "}
                    {userData?.bidBalance ? (Number(userData?.bidBalance) / 73).toFixed(0) : 0}
                </p>
                <div className="w-full bg-zinc-700 rounded-full h-2">
                    <div
                        className="bg-amber-500 h-2 rounded-full transition-all duration-500 ease-out"
                        style={{
                            width: `${
                                ((userData?.xp ?? 0) / (userData?.xpToNextLevel ?? 1)) * 100
                            }%`,
                        }}
                    ></div>
                </div>
                <p className="text-xs text-zinc-500 mt-1">
                    {userData?.xp || 0} / {userData?.xpToNextLevel || 1} XP
                </p>
            </div>
        </div>
    );
};

/**
 *
 */
const CountUp = ({ end, duration, decimals = 2, suffix = "" }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let startTime;
        let animationFrame;

        /**
         *
         */
        const animate = (timestamp) => {
            if (!startTime) {
                startTime = timestamp;
            }
            const progress = timestamp - startTime;
            const percentage = Math.min(progress / duration, 1);

            setCount(percentage * end);

            if (percentage < 1) {
                animationFrame = requestAnimationFrame(animate);
            }
        };

        animationFrame = requestAnimationFrame(animate);

        return () => cancelAnimationFrame(animationFrame);
    }, [end, duration]);

    return (
        <span>
            {count.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            {suffix}
        </span>
    );
};
/**
 *
 */
const TabNavigation = ({ activeTab, handleTabChange }) => (
    <div className="flex mb-6 border-b border-zinc-700/50 overflow-x-auto scrollbar-hide">
        {[
            { id: "overview", label: "Overview", path: "/profile" },
            { id: "achievements", label: "Achievements", path: "/profile/achievements" },
            { id: "settings", label: "Settings", path: "/profile/settings" },
        ].map((tab) => (
            <button
                key={tab.id}
                className={`py-2 px-4 font-medium ${
                    activeTab === tab.id
                        ? "text-amber-500 border-b-2 border-amber-500"
                        : "text-zinc-400 hover:text-white"
                }`}
                onClick={() => handleTabChange(tab.id)}
            >
                {tab.label}
            </button>
        ))}
    </div>
);

/**
 *
 */
const TabContent = ({ activeTab, userStats, achievements }) => {
    return (
        <>
            {activeTab === "settings" && <UserSettings achievements={achievements} />}
            {activeTab === "overview" && <OverviewTab userStats={userStats} />}
            {activeTab === "achievements" && (
                <div className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6">
                    <h2 className="text-xl font-medium text-zinc-100 mb-6">Achievements</h2>
                    {achievements.length > 0 ? (
                        <AchievementsDisplay achievements={achievements} />
                    ) : (
                        <div className="text-center text-zinc-400 py-8">
                            Loading achievements...
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

/**
 *
 */
const OverviewTab = ({ userStats }) => (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
        {userStats.map((stat, index) => (
            <div
                key={index}
                className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6 hover:border-zinc-600/50 transition-colors"
            >
                <div className="flex items-center gap-3 mb-2">
                    <stat.icon className={`w-5 h-5 ${stat.colors.icon}`} />
                    <h3 className="text-lg font-medium text-zinc-100">{stat.label}</h3>
                </div>
                <p className={`text-2xl font-mono ${stat.colors.value}`}>{stat.value}</p>
            </div>
        ))}
    </div>
);

/**
 *
 */
const ActivityTab = ({ walletAddress }) => {
    const [activityHistory, setActivityHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);

    useEffect(() => {
        // console.log(walletAddress);
        /**
         *
         */
        const fetchActivityHistory = async () => {
            if (!walletAddress) {
                return;
            }

            const ARBISCAN_API_KEY = process.env.REACT_APP_ARBISCAN_API_KEY; // Store this in your .env file
            const ARBISCAN_API_URL = "https://api.arbiscan.io/api";

            try {
                // console.log(
                //   "🚀 ~ fetchActivityHistory ~ ARBISCAN_API_URL:",
                //   ARBISCAN_API_URL
                // );
                // console.log(
                //   "🚀 ~ fetchActivityHistory ~ ARBISCAN_API_KEY:",
                //   ARBISCAN_API_KEY
                // );
                // console.log("🚀 ~ fetchActivityHistory ~ Starting Try Catch:");

                const response = await axios.get(ARBISCAN_API_URL, {
                    headers: {
                        Origin: "https://bidcoin.live", // Replace with your actual origin in production
                    },
                    params: {
                        action: "txlist",
                        address: walletAddress,
                        apikey: ARBISCAN_API_KEY,
                        endblock: "latest",
                        module: "account",
                        offset: 10,
                        page: page,
                        sort: "desc",
                        startblock: 0,
                    },
                });
                // console.log("🚀 ~ fetchActivityHistory ~ response:" + response);

                if (response.data.status === "1") {
                    const transactions = response.data.result;
                    const formattedActivity = transactions.map((tx) => ({
                        amount: `${parseFloat(tx.value) / 1e18} ETH`,
                        hash: tx.hash,
                        item: tx.to,
                        // Convert from Wei to ETH
                        time: new Date(tx.timeStamp * 1000).toLocaleString(),

                        type:
                            tx.to.toLowerCase() === walletAddress.toLowerCase()
                                ? "receive"
                                : "send",
                    }));

                    setActivityHistory((prevHistory) => [...prevHistory, ...formattedActivity]);
                } else {
                    console.error("Error fetching transactions:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching activity history:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchActivityHistory();
    }, [walletAddress, page]);

    /**
     *
     */
    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    return (
        <>
            <BlockchainOverlay loading={loading} />
            <div className="space-y-4">
                {activityHistory.map((activity, index) => (
                    <div
                        key={index}
                        className="bg-gray-800 rounded-lg p-4 flex justify-between items-center"
                    >
                        <div>
                            <p className="font-medium">{activity.item}</p>
                            <p className="text-sm text-gray-400">{activity.time}</p>
                        </div>
                        <div className="text-right">
                            <p
                                className={`font-bold ${
                                    activity.type === "receive"
                                        ? "text-green-500"
                                        : "text-yellow-500"
                                }`}
                            >
                                {activity.amount}
                            </p>
                            <p className="text-sm text-gray-400">
                                {activity.type.charAt(0).toUpperCase() + activity.type.slice(1)}
                            </p>
                        </div>
                    </div>
                ))}
                {activityHistory.length > 0 && (
                    <button
                        onClick={loadMore}
                        className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Load More
                    </button>
                )}
            </div>
        </>
    );
};

export default UserProfileRewards;

import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";
import AppRouter from "./Components/AppRouter";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import { onCLS, onFID, onLCP } from "web-vitals";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "./store/toastStyles.css";
// import { AuthProvider } from "./Services/AuthContext";
// import { LoadingProvider } from "./Services/LoadingContext";
// import store from "./app/store";
// import { Provider } from "react-redux";
const container = document.getElementById("root");
import ReactDOM from "react-dom";
import MigrationPage from "./Components/Common/MigrationPage";
Sentry.init({
    dsn: "https://3d94b76063ff4e2a1a0e0c713639bb7e@o4505849017270272.ingest.us.sentry.io/4508080612048896",
    // enabled in production only
    enabled: process.env.NODE_ENV === "production",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.5,

    // Session Replay
    replaysSessionSampleRate: 0.3,

    //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        // "localhost",
        "bidcoin.live",
        // "api.bidcoin.live",
        // "test.bidcoin.live",
        // "testing.bidcoin.live",
    ],

    // Tracing
    tracesSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    maskAllText: false,
    maskAllInputs: false,
    blockAllMedia: false,
    unblock: [".sentry-unblock, [data-sentry-unblock]"],
    unmask: [".sentry-unmask, [data-sentry-unmask]"],
});
LogRocket.init("eubsbl/bidcoin-beta");
onCLS(console.log);
onFID(console.log);
onLCP(console.log);
const root = createRoot(
    container,

    {
        // Callback called when an error is thrown and not caught by an Error Boundary.
        onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
            console.warn("Uncaught error", error, errorInfo.componentStack);
        }),
        // Callback called when React catches an error in an Error Boundary.
        onCaughtError: Sentry.reactErrorHandler(),
        // Callback called when React automatically recovers from errors.
        onRecoverableError: Sentry.reactErrorHandler(),
    }
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppRouter />
            {/* <MigrationPage /> */}
        </BrowserRouter>
    </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// // reportWebVitals();

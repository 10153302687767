import React from "react";
import { FaExclamationTriangle } from "react-icons/fa"; // Importing an icon from react-icons

/**
 * LeaveSiteModal Component
 *
 * A reusable modal component for confirming user actions. It displays a warning message
 * and provides options to proceed with the action or cancel it. The modal also supports
 * a loading state while waiting for the confirmation action to complete.
 *
 * Props:
 * - isOpen (boolean): Determines if the modal is visible.
 * - onConfirm (function): A callback function that is called when the user confirms the action.
 *   This function can be asynchronous and should return a promise.
 * - onClose (function): A callback function that is called when the modal is closed, either
 *   by clicking "Cancel" or after the confirmation action is completed.
 *
 * Example Usage:
 *
 ** const [isModalOpen, setModalOpen] = useState(false);
 **
 ** const handleConfirm = async () => {
 **     // Perform the action that requires confirmation
 **     await someAsyncFunction();
 ** };
 **
 ** return (
 **    <>
 **         <button onClick={() => setModalOpen(true)}>Open Modal</button>
 **         <LeaveSiteModal
 **             isOpen={isModalOpen}
 **             onConfirm={handleConfirm}
 **             onClose={() => setModalOpen(false)}
 **         />
 **     </>
 ** );
 */
export const LeaveSiteModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
            <div className="bg-gray-800 p-6 md:p-8 rounded-lg shadow-2xl transition-transform transform scale-100 hover:scale-105 max-w-md w-full mx-4">
                <div className="flex items-center mb-4 justify-center">
                    <FaExclamationTriangle className="text-yellow-400 text-3xl mr-2" />
                    <h2 className="text-lg md:text-xl font-bold text-white text-center">Warning</h2>
                </div>
                <p className="text-gray-300 mb-4 text-sm md:text-base text-center">
                    You are about to be redirected outside of BidCoin to an external URL. Don't
                    share your data. BidCoin will never ask you for your passwords or any other
                    sensitive data.
                </p>
                <div className="flex justify-center mt-4">
                    <button
                        className="mr-2 w-1/2 px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-500 transition duration-200"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg hover:from-blue-600 hover:to-blue-500 transition duration-200"
                        onClick={onConfirm}
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </div>
    );
};

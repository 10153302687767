import React, { useState, useRef, useEffect } from "react";
import { Bell } from "lucide-react";
import { useNotifications } from "../Services/NotificationContext";
import { format } from "date-fns";

const NotificationBell = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { notifications, markAsRead, clearAll } = useNotifications();
    const unreadCount = notifications.filter((n) => !n.read).length;
    const notificationRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div className="relative" ref={notificationRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="relative p-2 text-zinc-400 hover:text-zinc-100 transition-colors"
            >
                <Bell size={20} />
                {unreadCount > 0 && (
                    <span className="absolute top-0 right-0 bg-amber-500 text-zinc-900 rounded-full w-5 h-5 text-xs flex items-center justify-center">
                        {unreadCount}
                    </span>
                )}
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-2 w-80 bg-zinc-800 border border-zinc-700 rounded-lg shadow-lg z-50">
                    <div className="p-4 border-b border-zinc-700 flex justify-between items-center">
                        <h3 className="text-zinc-100 font-medium">Notifications</h3>
                        {notifications.length > 0 && (
                            <button
                                onClick={clearAll}
                                className="text-xs text-zinc-400 hover:text-zinc-100"
                            >
                                Clear all
                            </button>
                        )}
                    </div>
                    <div className="max-h-96 overflow-y-auto">
                        {notifications.length === 0 ? (
                            <div className="p-4 text-center text-zinc-400">No notifications</div>
                        ) : (
                            notifications.map((notification) => (
                                <div
                                    key={notification.id}
                                    className="p-4 border-b border-zinc-700 hover:bg-zinc-700/50 transition-colors"
                                    onClick={() => markAsRead(notification.id)}
                                >
                                    <div className="flex justify-between items-start">
                                        <h4 className="text-zinc-100 font-medium">
                                            {notification.title}
                                        </h4>
                                        <span className="text-xs text-zinc-400">
                                            {format(new Date(notification.timestamp), "HH:mm")}
                                        </span>
                                    </div>
                                    <p className="text-sm text-zinc-400 mt-1">
                                        {notification.message}
                                    </p>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationBell;

import { createAlchemyWeb3 } from "@alch/alchemy-web3";
// import { AlchemyProvider } from "ethers";
import { parseUnits, formatUnits } from "ethers";
import contractABI from "../assets/abis/v4/StakingContract.json";
import newAuctionABI from "../assets/abis/v4/NewAuction.json";

import tetherTokenABI from "../assets/abis/v4/CUSDT";
import bidCoinABI from "../assets/abis/v4/BidCoin";
import auctionFactoryAbi from "../assets/abis/v4/AuctionFactory.json";

import { getAppKitProvider, getAppKitAccount } from "../Services/AlchemyProvider";

import { ethers } from "ethers";

const stakingContractAddress = process.env.REACT_APP_STAKINGCONTRACT_ADDRESS;
const tetherTokenAddress = process.env.REACT_APP_CUSDT_ADDRESS;
const bidCoinAddress = process.env.REACT_APP_BIDCOIN_ADDRESS;
const auctionFactoryAddress = process.env.REACT_APP_AUCTIONFACTORY_ADDRESS;

const alchemyKey = "wss://arb-mainnet.g.alchemy.com/v2/z3GTWUvrqDHlJnoPCAfcAihbeVNJFkzg";
export const web3 = createAlchemyWeb3(alchemyKey);
const web3Provider = new ethers.JsonRpcProvider("https://arb1.arbitrum.io/rpc");

// export const tetherTokenContract = new web3.eth.Contract(tetherTokenABI.abi, tetherTokenAddress);

// export const bidCoinContract = new web3.eth.Contract(bidCoinABI.abi, bidCoinAddress);

// export const stakingContract = new web3.eth.Contract(contractABI.abi, stakingContractAddress);

export const tetherTokenContract = new ethers.Contract(
    tetherTokenAddress,
    tetherTokenABI.abi,
    web3Provider
);

export const bidCoinContract = new ethers.Contract(bidCoinAddress, bidCoinABI.abi, web3Provider);

export const stakingContract = new ethers.Contract(
    stakingContractAddress,
    contractABI.abi,
    web3Provider
);

export const auctionFactoryContract = new ethers.Contract(
    auctionFactoryAddress,
    auctionFactoryAbi.abi,
    web3Provider
);

/**
 *
 */

export const initializeAuctionContract = (contractAddress) => {
    return new web3.eth.Contract(newAuctionABI.abi, contractAddress);
};

/**
 * Gets the connected wallet address using AppKit
 */
const getWalletAddress = async () => {
    try {
        const userAddress = localStorage.getItem("userAddress");

        // console.log("🚀 ~ getWalletAddress ~ account:" + userAddress);

        return account?.address || "0x0";
    } catch (error) {
        console.error("Error getting wallet address:", error);
        return "0x0";
    }
};

/**
 * Modified loadUserData to use AppKit
 */
export const loadUserData = async () => {
    try {
        const [usdtBalanceV2, bidBalanceV2, stakerInfoV2, auctionsArrayV2] = await Promise.all([
            tetherTokenContract.balanceOf(userAddress),
            bidCoinContract.balanceOf(userAddress),
            stakingContract.stakers(userAddress),
            auctionFactoryContract.getAllAuctions(),
        ]);
        console.log({
            usdtBalance,
            bidBalance,
            stakerInfo,
            auctionsArray,
        });
        const userAddress = localStorage.getItem("userAddress");
        if (!userAddress || userAddress === "0x0") {
            return null;
        }
        // console.log("passed first check" + userAddress);

        const auctionsArray = await auctionFactoryContract.methods.getAllAuctions().call();
        let auctionsWonCount = 0;
        const auctionData = await Promise.all(
            auctionsArray.map(async (address) => {
                const contract = new web3.eth.Contract(newAuctionABI.abi, address);
                // console.log("🚀 ~ auctionsArray.map ~ auctionsArray:" + auctionsArray);
                const totalProfit = await contract.methods.auctionEnded().call();
                if (totalProfit == true) {
                    const winningBidder = await contract.methods.winningBidder().call();
                    // console.log(
                    //     "🚀 ~ auctionsArray.map ~ winningBidder:" +
                    //         winningBidder +
                    //         " + userAddress" +
                    //         userAddress
                    // );
                    if (winningBidder.toLowerCase() === userAddress.toLowerCase()) {
                        auctionsWonCount++;
                        // console.log(
                        //     "🚀 ~ auctionsArray.map ~ auctionsWonCount:" + auctionsWonCount
                        // );
                    }
                }
            })
        );
        if (!userAddress) {
            return;
        }
        // const totalStaked = await stakingContract.methods.totalStaked().call();
        const stakerInfo = await stakingContract.methods.stakers(userAddress).call();

        const usdtBalance = await tetherTokenContract.methods.balanceOf(userAddress).call();
        const bidBalance = await bidCoinContract.methods.balanceOf(userAddress).call();

        /**
         *
         */
        const safeFormatUnits = (value, decimals) => {
            try {
                return Number(formatUnits(value || "0", decimals)).toString();
            } catch (error) {
                console.error("Error formatting units:", error);

                return "0";
            }
        };

        // Query all auctions in auctionFactory contract function to getAllAuctions, and check state variable in each contract to see if winningBidder address == userAddress

        const userData = {
            address: stakingContractAddress,

            bidBalance: safeFormatUnits(bidBalance, 18),
            auctionsWonCount: auctionsWonCount,

            rewards: Number(formatUnits(stakerInfo.rewardAmount, 6)).toFixed(2),

            stakedAmount: Number(formatUnits(stakerInfo.stakedAmount, 18)).toFixed(2),
            // totalStaked: Number(formatUnits(totalStaked, 18)).toFixed(2),
            stakerInfo,
            usdtBalance: safeFormatUnits(usdtBalance, 6),
        };

        return userData;
    } catch (error) {
        // console.error("Error loading auction data:", error);
        return null;
    }
};

/**
 * Modified connectWallet to use AppKit
 */
export const connectWallet = async () => {
    try {
        const address = await getWalletAddress();
        if (address && address !== "0x0") {
            return {
                address,
                status: "",
            };
        }
        return {
            address: "",
            status: "No wallet connected",
        };
    } catch (err) {
        return {
            address: "",
            status: "😥 " + err.message,
        };
    }
};

/**
 * Modified getCurrentWalletConnected to use AppKit
 */
export const getCurrentWalletConnected = async () => {
    try {
        const address = await getWalletAddress();
        if (address && address !== "0x0") {
            return {
                address,
                status: "",
            };
        }
        return {
            address: "",
            status: "🦊 Connect wallet using the top right button.",
        };
    } catch (err) {
        return {
            address: "",
            status: "😥 " + err.message,
        };
    }
};

/**
 * Modified checkAndSwitchChain to use AppKit
 */
export const checkAndSwitchChain = async () => {
    try {
        const provider = await getAppKitProvider();
        if (!provider) return;

        const chainId = await provider.request({ method: "eth_chainId" });

        if (chainId !== "0xa4b1") {
            // 0xa4b1 is Arbitrum One
            await provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0xa4b1" }],
            });
            window.location.reload();
        }
    } catch (error) {
        console.error("Error switching chain:", error);
    }
};

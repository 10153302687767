import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    credentials: "include",
    headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
    },
    withCredentials: true,
});
instance.defaults.withCredentials = true;
axios.defaults.withCredentials = true;
instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        } else {
            const token = localStorage.getItem("siwe");
            config.headers["Authorization"] = `Bearer ${token?.token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default instance;

//import axios from 'axios';
//import { useAuth } from '../path/to/AuthContext';
//
//const instance = axios.create({
//  baseURL: process.env.REACT_APP_API_BASE_URL,
//  headers: {
//    'Content-Type': 'application/json',
//    'Accept': '*/*',
//  },
//  withCredentials: true,
//});
//
//instance.interceptors.response.use(
//  (response) => response,
//  async (error) => {
//    const { logout } = useAuth();
//    if (error.response && error.response.status === 401) {
//      // Handle unauthorized error
//      await logout();
//    }
//    return Promise.reject(error);
//  }
//);
//
//export default instance;

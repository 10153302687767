import React, { createContext, useContext, useState } from "react";
import { ExternalLink, AlertTriangle } from "lucide-react";

const LeaveSiteModalContext = createContext();

export const useLeaveSiteModal = () => {
    const context = useContext(LeaveSiteModalContext);
    if (!context) {
        throw new Error("useLeaveSiteModal must be used within a LeaveSiteModalProvider");
    }
    return context;
};

const Modal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
            onClick={handleOverlayClick}
        >
            <div className="bg-zinc-900 border border-zinc-800 rounded-xl p-6 max-w-md w-full shadow-xl">
                <div className="flex items-center gap-3 mb-4">
                    <AlertTriangle className="w-6 h-6 text-amber-500" />
                    <h2 className="text-xl font-medium text-zinc-100">Leaving BidCoin</h2>
                </div>

                <p className="text-zinc-300 mb-6">
                    You're being redirected to Arbiscan, an external blockchain explorer. Always
                    verify URLs and be cautious with external links.
                </p>

                <div className="flex flex-col sm:flex-row gap-3 sm:justify-end">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 rounded-lg border border-zinc-700 hover:bg-zinc-800 
                                 text-zinc-300 hover:text-zinc-100 transition-colors"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 rounded-lg bg-amber-500 hover:bg-amber-600 
                                 text-zinc-900 font-medium transition-colors inline-flex 
                                 items-center justify-center gap-2"
                    >
                        Continue
                        <ExternalLink className="w-4 h-4" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export const LeaveSiteModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [urlToOpen, setUrlToOpen] = useState("");

    const openModal = (url) => {
        setUrlToOpen(url);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setUrlToOpen("");
    };

    const handleConfirm = () => {
        window.open(urlToOpen, "_blank");
        closeModal();
    };

    return (
        <LeaveSiteModalContext.Provider value={{ openModal }}>
            {children}
            <Modal isOpen={isOpen} onClose={closeModal} onConfirm={handleConfirm} />
        </LeaveSiteModalContext.Provider>
    );
};

import React from "react";
import {
    DollarSign,
    Coins,
    TrendingUp,
    Users,
    Flame,
    ArrowRight,
    Home,
    Briefcase,
    Car,
    Gift,
    Zap,
    Shield,
    Plane,
} from "lucide-react";
import { Helmet } from "react-helmet-async";

/**
 *
 */
const HowItWorksScreen = () => {
    const steps = [
        {
            color: "bg-purple-500",
            description: "Bid on digital assets, real estate, cars, and more.",
            icon: Gift,
            title: "Diverse Auctions",
        },
        {
            color: "bg-green-500",
            description: "Fixed $0.01 bids increase auction price by $0.01.",
            icon: DollarSign,
            title: "Penny Bidding",
        },
        {
            color: "bg-red-500",
            description: "Use USDT or burn BID tokens to place bids.",
            icon: Flame,
            title: "Bid or Burn",
        },
        {
            color: "bg-yellow-500",
            description: "Earn USDT rewards by staking BID tokens.",
            icon: Coins,
            title: "StakeBack Rewards",
        },
        {
            color: "bg-blue-500",
            description: "Burn BID for free bids and increased staking limits.",
            icon: Zap,
            title: "BurnBack Benefits",
        },
        {
            color: "bg-indigo-500",
            description: "Vote on platform decisions and shape BidCoin's future.",
            icon: Users,
            title: "Governance",
        },
    ];

    /**
     *
     */
    const Gadgets = () => (
        <svg
            fill="#3b82f6"
            width="32"
            height="32"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            className="mb-2"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                {" "}
                <g id="Wellness_gadget">
                    {" "}
                    <path d="M92.7383,118.095A23.656,23.656,0,0,0,116.33,140.05H187.4a23.6555,23.6555,0,0,0,23.5913-21.9548h63.1221A18.887,18.887,0,0,0,292.979,99.2288V62.4818a18.887,18.887,0,0,0-18.8652-18.8662H210.9917A23.6556,23.6556,0,0,0,187.4,21.6606H116.33a23.6561,23.6561,0,0,0-23.5918,21.955H35.8149A18.8873,18.8873,0,0,0,16.9487,62.4818v36.747A18.8873,18.8873,0,0,0,35.8149,118.095ZM280.979,62.4818v36.747a6.8736,6.8736,0,0,1-6.8652,6.8662H211.06V55.6156h63.0542A6.8736,6.8736,0,0,1,280.979,62.4818ZM106.0264,43.4757a8.4691,8.4691,0,0,1,8.459-8.46h74.76a8.4691,8.4691,0,0,1,8.459,8.46v74.759a8.4691,8.4691,0,0,1-8.459,8.4595h-74.76a8.4691,8.4691,0,0,1-8.459-8.4595ZM28.9487,99.2288V62.4818a6.8738,6.8738,0,0,1,6.8662-6.8662H92.67V106.095H35.8149A6.8738,6.8738,0,0,1,28.9487,99.2288Z"></path>{" "}
                    <path d="M121.5439,86.367h14.2549a8.1317,8.1317,0,0,0,7.4649-4.9013l3.6006-8.3086,7.3,25.6035a8.0387,8.0387,0,0,0,7.38,5.8921c.1573.0088.3135.0127.4688.0127a8.0285,8.0285,0,0,0,7.51-5.0664l6.0507-14.8477h8.1534a5,5,0,0,0,0-10h-9.4073a8.1052,8.1052,0,0,0-7.5332,5.0654L162.3,90.8245,155.0508,65.4a8.1358,8.1358,0,0,0-15.2891-1.0034L134.5742,76.367h-13.03a5,5,0,0,0,0,10Z"></path>{" "}
                    <path d="M258.3159,88.1977a7.3425,7.3425,0,1,0-7.3428-7.3425A7.3423,7.3423,0,0,0,258.3159,88.1977Z"></path>{" "}
                    <circle cx="233.9063" cy="80.8552" r="7.3424"></circle>{" "}
                    <path d="M107.9414,303.9954V285.51h16.48v22.6048a15.04,15.04,0,1,0,11,0V285.51h3.7944A12.4146,12.4146,0,0,0,151.63,273.0953v-15.843h19.0234a19.6722,19.6722,0,1,0,0-10.9995H151.63v-13.96a12.4145,12.4145,0,0,0-12.4146-12.4146h-3.7944v-8.3807a1.007,1.007,0,0,1,1.0059-1.0059h12.4961a12.0193,12.0193,0,0,0,12.0058-12.0058v-.6491a19.6722,19.6722,0,1,0-11,0v.649a1.0069,1.0069,0,0,1-1.0058,1.0058H136.4268a12.0194,12.0194,0,0,0-12.0059,12.0059v8.3807H107.9414V192.95a15.0451,15.0451,0,1,0-10.5581.1614c-.1475-.0058-.2949-.0019-.4419-.0091v26.7768H94.0977a12.4145,12.4145,0,0,0-12.4146,12.4146v13.96H61.3506a19.6726,19.6726,0,1,0,0,10.9995H81.6831v15.843A12.4146,12.4146,0,0,0,94.0977,285.51h2.8437v18.4856a3.2148,3.2148,0,0,1-3.2109,3.212H86.958a19.6718,19.6718,0,1,0,0,11h6.7725A14.2272,14.2272,0,0,0,107.9414,303.9954Zm81.5874-59.915a7.6724,7.6724,0,1,1-7.6728,7.6724A7.6808,7.6808,0,0,1,189.5288,244.08Zm-41.7725-65.1187a7.6724,7.6724,0,1,1,7.6719,7.6729A7.6808,7.6808,0,0,1,147.7563,178.9617Zm-105.28,80.4634a7.6724,7.6724,0,1,1,7.6718-7.6723A7.6813,7.6813,0,0,1,42.4761,259.4251Zm25.6074,60.9541a7.6724,7.6724,0,1,1,7.6719-7.6718A7.6812,7.6812,0,0,1,68.0835,320.3792Zm25.3242-51.1737V236.1827a4.3627,4.3627,0,0,1,4.3628-4.3627h37.772a4.3626,4.3626,0,0,1,4.3623,4.3627v33.0228a4.3626,4.3626,0,0,1-4.3623,4.3627H97.7705A4.3627,4.3627,0,0,1,93.4077,269.2055Z"></path>{" "}
                    <path d="M382.209,379.06H369.42a29.25,29.25,0,0,0-27.0122-18.0279H272.6694a23.3858,23.3858,0,0,0-46.7715,0H158.7017A29.25,29.25,0,0,0,131.69,379.06H118.9a15.9783,15.9783,0,0,0-15.9785,15.9781V456.39A15.9784,15.9784,0,0,0,118.9,472.3684h23.7412a48.1868,48.1868,0,0,0,19.3516,12.6483c20.8208,7.282,49.353,9.9,78.6865-10.0977a17.5592,17.5592,0,0,1,19.75,0c29.334,19.9976,57.8662,17.38,78.687,10.0977a48.1857,48.1857,0,0,0,19.3511-12.6483H382.209A15.9783,15.9783,0,0,0,398.187,456.39V395.0384A15.9782,15.9782,0,0,0,382.209,379.06ZM249.2837,349.6945a11.3869,11.3869,0,1,1-11.3867,11.3869A11.3866,11.3866,0,0,1,249.2837,349.6945Zm83.8809,118.3048a74.22,74.22,0,0,1-24.482,4.3691c-13.2637,0-26.0815-4.1537-38.0981-12.3449a35.5871,35.5871,0,0,0-40.06,0c-12.0141,8.1907-24.833,12.3444-38.0972,12.3444a74.2213,74.2213,0,0,1-24.4829-4.3691,30.7928,30.7928,0,0,1-20.46-29.1333V390.2747A11.23,11.23,0,0,1,158.7017,379.06h75.644a23.3352,23.3352,0,0,0,29.876,0h78.186a11.23,11.23,0,0,1,11.2168,11.2169V438.863A30.7922,30.7922,0,0,1,333.1646,467.9993Z"></path>{" "}
                    <path d="M220.1826,324.5247a6,6,0,0,0,8.3057,8.6621,32.669,32.669,0,0,1,12.5068-6.6455c9.917-2.747,20.0117-.3554,30,7.1094a6,6,0,1,0,7.1836-9.6133C252.5586,304.8939,229.04,315.9847,220.1826,324.5247Z"></path>{" "}
                    <path d="M230.83,290.6673a66.6832,66.6832,0,0,0-25.6416,14.1035,6,6,0,0,0,8.3144,8.6534c1.292-1.2276,32.0869-29.7139,72.4864.4726a6,6,0,0,0,7.1836-9.6133C267.8389,285.3548,245.2891,286.43,230.83,290.6673Z"></path>{" "}
                    <path d="M473.439,21.6038H338.646a21.6124,21.6124,0,0,0-21.6123,21.6123V297.3783a21.6124,21.6124,0,0,0,21.6123,21.6123H473.439a21.6124,21.6124,0,0,0,21.6123-21.6123V43.2161A21.6124,21.6124,0,0,0,473.439,21.6038ZM423.4365,34.7972h9.8916a6,6,0,0,1,0,12h-9.8916a6,6,0,0,1,0-12Zm-44.68,0h23.69a6,6,0,0,1,0,12h-23.69a6,6,0,0,1,0-12Zm99.104,256.2036a11.4464,11.4464,0,0,1-11.4462,11.4467H345.67a11.4464,11.4464,0,0,1-11.4463-11.4467V68.9627A11.4462,11.4462,0,0,1,345.67,57.5162H466.4146a11.4462,11.4462,0,0,1,11.4462,11.4465Z"></path>{" "}
                    <path d="M356.1426,116h19.5063a4.7959,4.7959,0,0,1,4.7959,4.7959v56.4066a4.7959,4.7959,0,0,1-4.7959,4.7959H356.1426a4.7964,4.7964,0,0,1-4.7964-4.7964V120.7967A4.7964,4.7964,0,0,1,356.1426,116Z"></path>{" "}
                    <rect
                        height="39.5073"
                        rx="4.7964"
                        width="29.0986"
                        x="391.4932"
                        y="142.4914"
                    ></rect>{" "}
                    <rect
                        height="85.6705"
                        rx="4.7964"
                        width="29.0986"
                        x="431.6401"
                        y="96.3282"
                    ></rect>{" "}
                    <path d="M455.9824,204.9486H355.2764a6,6,0,0,0,0,12h100.706a6,6,0,0,0,0-12Z"></path>{" "}
                    <path d="M455.9824,230.3621H430.8057a6,6,0,0,0,0,12h25.1767a6,6,0,0,0,0-12Z"></path>{" "}
                    <path d="M405.63,230.3621H355.2764a6,6,0,0,0,0,12H405.63a6,6,0,0,0,0-12Z"></path>{" "}
                </g>{" "}
            </g>
        </svg>
    );

    const auctionCategories = [
        { icon: Home, name: "Real Estate" },
        { icon: DollarSign, name: "Digital Assets" },
        { icon: Car, name: "Vehicles" },
        { icon: Gadgets, name: "Electronics" },
        { icon: Plane, name: "Travel" },
    ];

    return (
        <>
            <Helmet>
                <title>How It Works - BidCoin</title>
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@BidCoinNews" />
                <meta name="twitter:title" content="How It Works - BidCoin" />
                <meta
                    name="twitter:description"
                    content="Decentralized Penny Auction, the most advanced bidding platform of Web3."
                />
                <meta name="twitter:image" content="https://bidcoin.live/favicon.ico" />
                <meta property="og:title" content="How It Works - BidCoin" />
                <meta
                    property="og:description"
                    content="Decentralized Penny Auction, the most advanced bidding platform of Web3."
                />
                <meta property="og:image" content="https://bidcoin.live/favicon.ico" />
                <meta property="og:url" content="https://bidcoin.live" />
            </Helmet>
            <div className="min-h-screen bg-gradient-to-b mt-20 from-zinc-900 via-zinc-900 to-black text-zinc-100">
                <main className="container mx-auto px-4 py-8">
                    <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-amber-500 to-amber-300 bg-clip-text text-transparent">
                        How It Works
                    </h2>

                    <div className="bg-zinc-800/50 rounded-2xl p-6 mb-12 text-center backdrop-blur-sm border border-zinc-700/50">
                        <h3 className="text-2xl font-bold mb-2">Earn Real Value</h3>
                        <p className="text-lg text-zinc-400">
                            Participate in auctions, stake tokens, and earn rewards in{" "}
                            <span className="font-bold text-amber-500">USDT</span>
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
                        {steps.map((step, index) => (
                            <div
                                key={index}
                                className="bg-zinc-800/50 rounded-xl p-6 backdrop-blur-sm border border-zinc-700/50 hover:border-amber-500/50 transition-all duration-300"
                            >
                                <div className="bg-amber-500 rounded-full p-3 inline-block mb-4">
                                    <step.icon size={24} className="text-zinc-900" />
                                </div>
                                <h3 className="text-xl font-bold mb-2 text-zinc-100">
                                    {step.title}
                                </h3>
                                <p className="text-zinc-400">{step.description}</p>
                            </div>
                        ))}
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                        <div className="bg-zinc-800/50 rounded-xl p-6 backdrop-blur-sm border border-zinc-700/50">
                            <h3 className="text-2xl font-bold mb-4 flex items-center text-zinc-100">
                                <Coins className="mr-2 text-amber-500" /> StakeBack System
                            </h3>
                            <ul className="list-disc list-inside space-y-2 text-zinc-400">
                                <li>Stake BID tokens to earn platform rewards</li>
                                <li>Up to 20% share of auction profits</li>
                                <li>Direct correlation to platform success</li>
                                <li>Receive rewards in USDT</li>
                            </ul>
                        </div>
                        <div className="bg-zinc-800/50 rounded-xl p-6 backdrop-blur-sm border border-zinc-700/50">
                            <h3 className="text-2xl font-bold mb-4 flex items-center text-zinc-100">
                                <Flame className="mr-2 text-amber-500" /> BurnBack System
                            </h3>
                            <ul className="list-disc list-inside space-y-2 text-zinc-400">
                                <li>Burn BID tokens for free bids</li>
                                <li>Increase your staking limit</li>
                                <li>Contribute to token scarcity</li>
                                <li>VIP tiers offer enhanced burning benefits</li>
                            </ul>
                        </div>
                    </div>

                    <div className="bg-zinc-800/50 rounded-xl p-8 backdrop-blur-sm border border-zinc-700/50 mb-12">
                        <h3 className="text-2xl font-bold mb-6 text-zinc-100">
                            Auction Categories
                        </h3>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                            {auctionCategories.map((category, index) => (
                                <div
                                    key={index}
                                    className="flex flex-col items-center bg-zinc-800/80 rounded-xl p-4 hover:border-amber-500/50 border border-zinc-700/50 transition-all duration-300"
                                >
                                    <category.icon size={32} className="text-amber-500 mb-2" />
                                    <span className="text-sm font-medium text-zinc-100">
                                        {category.name}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="bg-zinc-800/50 rounded-xl p-8 backdrop-blur-sm border border-zinc-700/50">
                        <h3 className="text-2xl font-bold mb-4 text-zinc-100">Getting Started</h3>
                        <ol className="space-y-4">
                            {[
                                "Create your BidCoin account",
                                // "Join the presale to acquire BID tokens",
                                "Explore auctions and place $0.01 bids",
                                "Stake tokens to earn USDT rewards (StakeBack)",
                                "Burn tokens for free bids and increased limits (BurnBack)",
                                "Climb VIP tiers for enhanced rewards",
                            ].map((step, index) => (
                                <li key={index} className="flex items-start">
                                    <span className="bg-amber-500 text-zinc-900 rounded-full w-6 h-6 flex items-center justify-center mr-3 flex-shrink-0">
                                        {index + 1}
                                    </span>
                                    <span className="text-zinc-400">{step}</span>
                                </li>
                            ))}
                        </ol>
                    </div>

                    {/* <div className="mt-12 text-center">
          <button className="bg-amber-500 text-zinc-900 py-3 px-8 rounded-xl hover:bg-amber-600 transition-all duration-200 text-lg font-bold inline-flex items-center">
            Start Your BidCoin Journey <ArrowRight size={20} className="ml-2" />
          </button>
        </div> */}
                </main>
            </div>
        </>
    );
};

export default HowItWorksScreen;

import { createAlchemyWeb3 } from "@alch/alchemy-web3";
// import { AlchemyProvider } from "ethers";
import { parseUnits, formatUnits } from "ethers";
import contractABI from "../assets/abis/v4/StakingContract.json";
import tetherTokenABI from "../assets/abis/v4/CUSDT";
import bidCoinABI from "../assets/abis/v4/BidCoin";
import treasuryABI from "../assets/abis/v4/Treasury.json";
import { getAppKitAccount } from "./AlchemyProvider";
import { ethers } from "ethers";

const stakingContractAddress = process.env.REACT_APP_STAKINGCONTRACT_ADDRESS;
const tetherTokenAddress = process.env.REACT_APP_CUSDT_ADDRESS;
const bidCoinAddress = process.env.REACT_APP_BIDCOIN_ADDRESS;
const treasuryAddress = process.env.REACT_APP_TREASURY_ADDRESS;

const alchemyKey = "wss://arb-mainnet.g.alchemy.com/v2/z3GTWUvrqDHlJnoPCAfcAihbeVNJFkzg";
// const web3 = createAlchemyWeb3(alchemyKey);
const web3Provider = new ethers.JsonRpcProvider("https://arb-mainnet.g.alchemy.com/v2/z3GTWUvrqDHlJnoPCAfcAihbeVNJFkzg");

export const tetherTokenContract = new ethers.Contract(
    tetherTokenAddress,
    tetherTokenABI.abi,
    web3Provider
);

export const bidCoinContract = new ethers.Contract(bidCoinAddress, bidCoinABI.abi, web3Provider);

export const stakingContract = new ethers.Contract(
    stakingContractAddress,
    contractABI.abi,
    web3Provider
);

export const treasuryContract = new ethers.Contract(treasuryAddress, treasuryABI.abi, web3Provider);
/**
 *
 */
export const claimRewardsHandler = async () => {
    try {
        const addressArray = await window.ethereum.request({
            method: "eth_accounts",
        });
        const userAddress = addressArray.length > 0 ? addressArray[0] : "0x0";
        const stakerInfo = await stakingContract.stakers(userAddress);
        const rewardAmount = stakerInfo.rewardAmount;
        // await stakingContract.claimProfit().send({ from: userAddress });

        // Claim rewards
        const claimTransactionParameters = {
            data: treasuryContract.claimReward(rewardAmount).encodeABI(),
            from: userAddress,
            to: treasuryAddress,
        };

        const claimTxHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [claimTransactionParameters],
        });
        // Wait for approval transaction to be mined
        await new Promise((resolve, reject) => {
            /**
             *
             */
            const checkTx = async () => {
                const receipt = await web3.eth.getTransactionReceipt(claimTxHash);
                console.log("receipt", receipt);
                if (receipt) {
                    resolve();
                } else {
                    setTimeout(checkTx, 1000);
                }
            };
            checkTx();
        });
        return true;
    } catch (error) {
        console.error("Error claiming rewards:", error);

        return error;
    }
};

/**
 *
 */
export const loadStakingData = async () => {
    try {
        const userAddress = localStorage.getItem("userAddress");
        if (!userAddress || userAddress === "0x0") {
            return null;
        }

        const totalStaked = await stakingContract.totalStaked();
        const stakerInfo = await stakingContract.stakers(userAddress);
        console.log("stakerInfo", stakerInfo);
        /**
         * 
Result(3)
0
: 
4200000000000000000n // Staked Amount
1
: 
398391n // Reward Amount
2
: 
0n // Unstake Request Time
         */
        const usdtBalance = await tetherTokenContract.balanceOf(userAddress);
        const bidBalance = await bidCoinContract.balanceOf(userAddress);

        /**
         *
         */
        const safeFormatUnits = (value, decimals) => {
            try {
                return Number(formatUnits(value || "0", decimals)).toString();
            } catch (error) {
                console.error("Error formatting units:", error);

                return "0";
            }
        };

        let timeFromBlockUnstakeTime;
        if (stakerInfo[2] != "0") {
            // Convert unstakeRequestTime to date (in UTC)
            const unstakeRequestDate = new Date(stakerInfo[2] * 1000);

            // Calculate the release date (30 days after unstake request)
            const releaseDate = new Date(unstakeRequestDate.getTime() + 30 * 24 * 60 * 60 * 1000);

            // Get the current date (in UTC)
            const currentDate = new Date();

            // Calculate the difference in time (milliseconds)
            const timeDifference = releaseDate.getTime() - currentDate.getTime();

            // Convert time difference to days
            const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

            if (daysLeft > 0) {
                timeFromBlockUnstakeTime = `${daysLeft} days left`;
            } else {
                timeFromBlockUnstakeTime = "Tokens are available for claim";
            }
        } else {
            timeFromBlockUnstakeTime = null;
        }
        const stakingData = {
            address: stakingContractAddress,
            bidBalance: safeFormatUnits(bidBalance, 18),
            rewards: Number(formatUnits(stakerInfo[1], 6)).toFixed(2),
            stakedAmount: Number(formatUnits(stakerInfo[0], 18)).toFixed(2),
            stakerInfo,
            totalStaked: Number(formatUnits(totalStaked, 18)).toFixed(2),
            unstakeRequestTime: timeFromBlockUnstakeTime,
            usdtBalance: safeFormatUnits(usdtBalance, 6),
        };

        return stakingData;
    } catch (error) {
        console.error("Error loading staking data:", error);

        return null;
    }
};

/**
 *
 */
export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                address: addressArray[0],
                status: "",
            };

            return obj;
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                    <p>
                        {" "}
                        {/* 🦊{" "} */}
                        <a target="_blank" href={"https://metamask.io/download"}>
                            {/* You must install Metamask, a virtual Ethereum wallet, in your browser. */}
                        </a>
                    </p>
                </span>
            ),
        };
    }
};

/**
 *
 */
export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    status: "👆🏽 Input Stake / Unstake Amount.",
                };
            } else {
                return {
                    address: "",
                    status: "🦊 Connect to Metamask using the top right button.",
                };
            }
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                    <p>
                        {" "}
                        {/* 🦊{" "} */}
                        <a target="_blank" href={"https://metamask.io/download"}>
                            {/* You must install Metamask, a virtual Ethereum wallet, in your browser. */}
                        </a>
                    </p>
                </span>
            ),
        };
    }
};

/**
 *
 */
export const stakeTokensHandler = async (address, stakeInput) => {
    const provider = window.ethereum;
    if (!provider || !address) {
        return {
            status: "💡 Connect your wallet provider to continue.",
        };
    }

    try {
        const bidCoinAmount = parseUnits(stakeInput.toString(), 18);

        // Check Allowance
        const allowance = await bidCoinContract.methods.allowance(address, stakingContractAddress);
        if (allowance < bidCoinAmount) {
            // Approve spending of BidCoin
            const approveTransactionParameters = {
                data: bidCoinContract.methods
                    .approve(stakingContractAddress, bidCoinAmount)
                    .encodeABI(),
                from: address,
                to: bidCoinAddress,
            };

            const approveTxHash = await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [approveTransactionParameters],
            });

            // Wait for approval transaction to be mined
            await new Promise((resolve) => {
                /**
                 *
                 */
                const checkTx = async () => {
                    const receipt = await web3.eth.getTransactionReceipt(approveTxHash);
                    if (receipt) {
                        resolve();
                    } else {
                        setTimeout(checkTx, 1000);
                    }
                };
                checkTx();
            });

            // return { status: "✅ Approval successful. Proceeding with staking..." };
        }

        // Stake tokens
        const stakeTransactionParameters = {
            data: stakingContract.stake(address, bidCoinAmount).encodeABI(),
            from: address,
            to: stakingContractAddress,
        };

        const stakeTxHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [stakeTransactionParameters],
        });

        // Wait for staking transaction to be mined
        await new Promise((resolve) => {
            /**
             *
             */
            const checkTx = async () => {
                const receipt = await web3.eth.getTransactionReceipt(stakeTxHash);
                if (receipt) {
                    resolve();
                } else {
                    setTimeout(checkTx, 1000);
                }
            };
            checkTx();
        });

        // Fetch updated staking data
        const totalStaked = await stakingContract.totalStaked();
        const stakerInfo = await stakingContract.stakers(address);
        const usdtBalance = await tetherTokenContract.balanceOf(address);
        const bidBalance = await bidCoinContract.balanceOf(address);

        /**
         *
         */
        const safeFormatUnits = (value, decimals) => {
            try {
                return Number(formatUnits(value || "0", decimals)).toString();
            } catch (error) {
                console.error("Error formatting units:", error);

                return "0";
            }
        };
        let timeFromBlockUnstakeTime;
        if (stakerInfo[2] != "0") {
            // Convert unstakeRequestTime to date (in UTC)
            const unstakeRequestDate = new Date(stakerInfo[2] * 1000);

            // Calculate the release date (30 days after unstake request)
            const releaseDate = new Date(unstakeRequestDate.getTime() + 30 * 24 * 60 * 60 * 1000);

            // Get the current date (in UTC)
            const currentDate = new Date();

            // Calculate the difference in time (milliseconds)
            const timeDifference = releaseDate.getTime() - currentDate.getTime();

            // Convert time difference to days
            const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

            if (daysLeft > 0) {
                timeFromBlockUnstakeTime = `${daysLeft} days left`;
            } else {
                timeFromBlockUnstakeTime = "Tokens are available for claim";
            }
        } else {
            timeFromBlockUnstakeTime = "No unstake request";
        }
        const stakingData = {
            address: stakingContractAddress,
            bidBalance: safeFormatUnits(bidBalance, 18),
            rewards: Number(formatUnits(stakerInfo[1], 6)).toFixed(2),
            stakedAmount: Number(formatUnits(stakerInfo[0], 18)).toFixed(2),
            stakerInfo,
            totalStaked: Number(formatUnits(totalStaked, 18)).toFixed(2),
            unstakeRequestTime: timeFromBlockUnstakeTime,
            usdtBalance: safeFormatUnits(usdtBalance, 6),
        };

        return stakingData;
    } catch (error) {
        console.error("Staking error:", error);

        return {
            status: "❌ Staking failed: " + error.message,
        };
    }
};

/**
 *
 */
export const unstakeTokensHandler = async (address, unstakeInput) => {
    if (!window.ethereum || address === null) {
        return {
            status: "💡 Connect your wallet provider to continue.",
        };
    }

    try {
        // Get the current discount fee
        // const discountFee = await stakingContract.discountFee();
        const usdtAmountInWei = parseUnits(unstakeInput.toString(), 18);
        // Approve spending of USDT

        // Place the bid
        const unstakeTransactionParameters = {
            data: stakingContract.unStake(usdtAmountInWei).encodeABI(),
            from: address,
            to: stakingContractAddress,
        };

        const unstakeTxHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [unstakeTransactionParameters],
        });

        console.log("unstakeTxHash", unstakeTxHash);

        const totalStaked = await stakingContract.totalStaked();
        const stakerInfo = await stakingContract.stakers(address);

        const addressArray = await window.ethereum.request({
            method: "eth_accounts",
        });

        const userAddress = addressArray.length > 0 ? addressArray[0] : "0x0";
        const usdtBalance = await tetherTokenContract.balanceOf(userAddress);
        const bidBalance = await bidCoinContract.balanceOf(userAddress);

        /**
         *
         */
        const safeFormatUnits = (value, decimals) => {
            try {
                return Number(formatUnits(value || "0", decimals)).toString();
            } catch (error) {
                console.error("Error formatting units:", error);

                return "0";
            }
        };
        let timeFromBlockUnstakeTime;
        if (stakerInfo.unstakeRequestTime != "0") {
            // Convert unstakeRequestTime to date (in UTC)
            const unstakeRequestDate = new Date(stakerInfo[2] * 1000);

            // Calculate the release date (30 days after unstake request)
            const releaseDate = new Date(unstakeRequestDate.getTime() + 30 * 24 * 60 * 60 * 1000);

            // Get the current date (in UTC)
            const currentDate = new Date();

            // Calculate the difference in time (milliseconds)
            const timeDifference = releaseDate.getTime() - currentDate.getTime();

            // Convert time difference to days
            const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

            if (daysLeft > 0) {
                timeFromBlockUnstakeTime = `${daysLeft} days left`;
            } else {
                timeFromBlockUnstakeTime = "Tokens are available for claim";
            }
        } else {
            timeFromBlockUnstakeTime = "No unstake request";
        }
        const stakingData = {
            address: stakingContractAddress,
            bidBalance: safeFormatUnits(bidBalance, 18),
            rewards: Number(formatUnits(stakerInfo[1], 6)).toFixed(2),
            stakedAmount: Number(formatUnits(stakerInfo[0], 18)).toFixed(2),
            stakerInfo,
            totalStaked: Number(formatUnits(totalStaked, 18)).toFixed(2),
            unstakeRequestTime: timeFromBlockUnstakeTime,
            usdtBalance: safeFormatUnits(usdtBalance, 6),
        };

        return stakingData;
    } catch (error) {
        return {
            status: "😥 " + error.message,
        };
    }
};

const getWalletAddress = async () => {
    const account = await getAppKitAccount();
    return account?.address || "0x0";
};

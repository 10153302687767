import { useAppKit, useAppKitEvents } from "@reown/appkit/react";
import React, { useState, useEffect } from "react";

export const AddressDisplay = () => {
    const [address, setAddress] = useState(localStorage.getItem("userAddress"));
    const { open } = useAppKit();
    const { events } = useAppKitEvents();
    const truncatedAddress = `${address?.slice(0, 6)}...${address?.slice(-4)}`;
    const iconStyle = {
        width: "8px",
        height: "8px",
        display: "inline-block",
        marginRight: "4px",
        backgroundColor: "#4CAF50",
        borderRadius: "50%",
    };
    const handleClick = () => {
        open();
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setAddress(localStorage.getItem("userAddress"));
        }, 3000); // Update every 5 seconds

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, []);
    if (address == "undefined") return null;
    return (
        <button onClick={handleClick}>
            <div className="flex items-center gap-2">
                <div style={address ? iconStyle : { display: "none" }} />
                <span className="text-zinc-300">{address ? truncatedAddress : ""}</span>
            </div>
        </button>
    );
};
